//import Vue from 'vue';
import {createRouter, createWebHistory} from 'vue-router'  
import firebase from "firebase/compat/app";


import Login from '../components/LoginPage'
const DashBoard  = () => import( '../components/DashBoard')
import HomePage from '../components/HomePage';

import EmployersPage from '../components/AgencyDetail';

const ProfilePage    = () => import(   '../components/profile/ProfilePage')
const BasicInfo    = () => import(   '../components/profile/BasicInfo')
const JobSearch    = () => import(   '../components/profile/JobSearch')
const WorkExperience    = () => import(   '../components/profile/WorkExperience')
const EducationPage    = () => import(   '../components/profile/EducationPage')
const UploadDocument  = () => import(    '../components/profile/UploadDocuments')
const BackgroundQuestions  = () => import(    '../components/profile/BackgroundQuestions')
import SearchJobs from '../components/SearchJobs'
const AddReference = () => import(    '../components/references/NewReference')
import JobDetails from '../components/JobDetails'
const MyReferences = () => import('../components/profile/MyReferences');
import ViewInvites from '../components/profile/ViewInvites';
const CompanyProfile   = () => import(  '../components/agency/CompanyProfile')
const SpecialtiesManagement  = () => import(  '../components/agency/SpecialtiesManagement')
const CreateLink   = () => import(  '../components/agency/CreateLink')
const AgencyTemplates   = () => import(  '../components/agency/UpdateTemplates')
const AddJob   = () => import(  '../components/agency/AddJob')
const InviteRecruiter   = () => import(  '../components/agency/InviteRecruiter')
const MyProfile   = () => import(  '../components/agency/MyProfile')
const PendingJobs   = () => import(  '../components/agency/PendingJobs')
const ViewApplication   = () => import(  '../components/agency/ViewApplication')
const ViewApplications   = () => import(   '../components/profile/ListApplications')
const ChatNow   = () => import(   '../components/profile/ChatNow')

import PermissionDenied from '../components/PermissionDenied'



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
        path: '/__auth/action',
        name: 'Reset Password',
        component: () => import('../components/AuthActions'),
        meta: {back: '/'}
    },
    {
        path: '/w',
        name: 'WLSearch',
        component: () => import(   '../components/wl/SearchJobs'),
        meta: {back: false, layout:"BlankLayout"},
    },
    {
        path: '/admin/lookup',
        name: 'lookup',
        component: () => import(   '../components/admin/LookupInfo'),
        meta: {back: false,  auth: ["super_admin"]},
    },
    {
        path: '/admin/nurse/:nurse?',
        name: 'nursedetail',
        component: () => import(   '../components/admin/NurseDetail'),
        meta: {back: false,  auth: ["super_admin"]},
    },
    {
        path: '/w/j/:id?/:location?/:title?',
        name: 'WLJobDetails',
        component: () => import(   '../components/wl/JobDetails'),
        meta: {back: false, layout:"BlankLayout"},
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {back: "/"},
    },
    { path: '/register', redirect: "/pages/register" },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashBoard,
        meta: {back: false, auth: ["nurse","recruiter", "agency_admin", "super_admin"]},
    },
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        meta: {back: false, containerClass:"container-fluid", title:"Refined Health Pros"},
    },
    {
        path: '/agencies/:agencyId', 
        name: 'EmployersPage',
        component: EmployersPage,
        meta: {back: false, containerClass:"container", title:"Employers"},
    },
    {
        path: '/profile',
        name: 'ProfilePage',
        component: ProfilePage,
        meta: {back: "/dashboard", auth: ["nurse"]},
    },
    {
        path: '/profile/basicinfo',
        name: 'BasicInfo',
        component: BasicInfo,
        meta: {back: "/profile", auth: ["nurse"]},
    },
    {
        path: '/profile/jobsearch',
        name: 'JobSearch',
        component: JobSearch,
        meta: {back: "/dashboard", auth: ["nurse"]},
    },
    {
        path: '/profile/workexperience/:id?', 
        name: 'WorkExperience',
        component: WorkExperience,
        meta: {back: "/profile", auth: ["nurse"]},
    },
    {
        path: '/profile/education/:id?', 
        name: 'Education',
        component: EducationPage,
        meta: {back: "/profile"}, auth: ["nurse"],
    }
    ,
    {
        path: '/profile/uploadDocument', 
        name: 'Upload Document',
        component: UploadDocument,
        meta: {back: "/profile", auth: ["nurse"]},
    },
    {
        path: '/searchjobs/:query?', 
        name: 'Search Jobs',
        component: SearchJobs,
        meta: {back: false},
    },
    {
        path: '/blog/:slug', 
        name: 'Blog Entry',
        component: () => import(   '../components/BlogEntry'),
        meta: {back: "/blog"},
    },
    {
        path: '/pages/:slug', 
        name: 'Page Display',
        component: () => import(   '../components/PageDisplay'),
        meta: {back: "/", containerClass:"container-fluid"},
    },
    {
        path: '/blog', 
        name: 'Blog Entries',
        component: () => import(   '../components/BlogEntries'),
        meta: {back: "/blog" , containerClass:"container-fluid"},
    },/*
    {
        path: '/jobdetails/:id?/:name?/:invite?', 
        name: 'JobDetails',
        component: JobDetails,
        meta: {back: "/searchjobs"},
    },*/
    {
        path: '/jobs/:id?/:city?/:title?', 
        name: 'JobDetails',
        component: JobDetails,
        meta: {back: "/searchjobs"},
    },
    {
        path: '/app/:id?', 
        name: 'JobInvite',
        component: JobDetails,
        meta: {back: "/searchjobs"},
    },
    {
        path: '/ref/:reqId', 
        name: 'AddReference',
        component: AddReference,
        meta: {},
    },
    {
        path: '/profile/backgroundquestions', 
        name: 'Background Questions',
        component: BackgroundQuestions,
        meta: {back: "/profile", auth: ["nurse"]},
    },
    {
        path: '/profile/myreferences/:id?', 
        name: 'References',
        component: MyReferences,
        meta: {back: "/profile", auth: ["nurse"]},
    },
    {
        path: '/agency/companyprofile', 
        name: 'Company Profile',
        component: CompanyProfile,
        meta: {back: "/dashboard", auth: ["agency_admin"]},
    },
    {
        path: '/agency/specialtiesmanagement', 
        name: 'Specialties Management',
        component: SpecialtiesManagement,
        meta: {back: "/dashboard", auth: ["agency_admin"]},
    },
    {
        path: '/agency/createlink/:id?', 
        name: 'Create Link',
        component: CreateLink,
        meta: {back: "/dashboard", auth: ["agency_admin", "recruiter"]},
    },
    {
        path: '/agency/templates',
        name: 'Agency Templates',
        component: AgencyTemplates,
        meta: {back: "/dashboard", auth: ["agency_admin"]},
    },
    {
        path: '/agency/addajob/:id?/:source?',
        name: 'Add a Job',
        component: AddJob,
        meta: {back: "/dashboard", auth: ["agency_admin"]},
    },
    {
        path: '/agency/inviterecruiter',
        name: 'Invite a Recruiter',
        component: InviteRecruiter,
        meta: {back: "/dashboard", auth: ["agency_admin"]},
    }
    ,
    {
        path: '/profile/viewinvites',
        name: 'View Invites',
        component: ViewInvites,
        meta: {back: "/dashboard", auth: ["nurse", "recruiter", "agency_admin"]},
    },
    {
        path: '/agency/myprofile',
        name: 'My Profile',
        component: MyProfile,
        meta: {back: "/dashboard", auth: ["recruiter", "agency_admin"]},
    },
    {
        path: '/agency/application/:jobId/:id',
        name: 'View Application',
        component: ViewApplication,
        meta: {back: "/dashboard", auth: ["recruiter", "agency_admin", "super_admin"]},
    },
    {
        path: '/agency/pendingjobs',
        name: 'Pending Jobs',
        component: PendingJobs,
        meta: {back: "/dashboard", auth: ["agency_admin"]},
    },
    {
        path: '/profile/applications',
        name: 'View Apps',
        component: ViewApplications,
        meta: {back: "/dashboard", auth: ["nurse"]},
    },
    {
        path: '/profile/chatnow/:id',
        name: 'Chat Now',
        component: ChatNow,
        meta: {back: "/jobdetails/:id", auth: ["nurse", "recruiter", "agency_admin"]},
    }
    ,
    {
        path: '/permissiondenied',
        name: 'Permission Denied',
        component: PermissionDenied,
        meta: {back: "/permissiondenied"},
    },
    {
        path: '/aboutus',
        redirect: '/pages/aboutus',
        name: 'About Us',
        meta: {back: "/", containerClass:"container-fluid"},
    },

    ]
})

// in some global file
async function getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        user.getIdTokenResult().then((result) => {
            var role = result.claims.role ? result.claims.role : { type: "nurse" };
            if (!role.type) {
                role.type = "nurse";
            }
            unsubscribe();
            resolve(role);
        });
      }, reject);
    });
  }

router.beforeEach(async (to, from, next) => {

    if (!to.meta.auth) {
      next();
    } else {
      const currentUser = await getCurrentUser();
      console.log("Current User", currentUser);
      if (currentUser) {
        
        if (to.meta.auth.includes(currentUser.type)) {
            next();
        } else {
            next({ name: "Permission Denied" });
        }        
      } else {
        next({ name: "login" });
      }
    }
});

export default router