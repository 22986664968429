
function getJobUrl(semantic, city, title, isWL, base) {
    
    if (!base) 
        base = "";

    if (!isWL)
        return base + "/jobs/" + semantic + "/" + city.replaceAll(",", "").replaceAll(" ", "_") + "/" + title.replaceAll(" - ", "_").replaceAll(" ", "_").replaceAll("/", "-");
    else 
        return base + "/w/j/" + semantic + "/" + city.replaceAll(",", "").replaceAll(" ", "_") + "/" + title.replaceAll(" - ", "_").replaceAll(" ", "_");

}


export {getJobUrl}