<template>

  <LoginBox />



</template>

<script>
import LoginBox from './molecules/LoginBox.vue';
export default {
    components: [LoginBox],
    
    computed: {
      
    }
}



</script>