<template>
  <div class="" itemscope itemtype="https://schema.org/JobPosting">


    <JobShortDetails :item="item" @apply="applyNow" :applicationExists="hasApplied" :appInProgress="applyingNow"
      v-if="isLoaded" />

    <div class="share">
      <twitter-button v-if="shareUrl" :url="shareUrl" :description="shareDesc" btnText=""
        class="border-0 share-button--outline" :isBlank="false" />
      <facebook-button v-if="shareUrl" :url="shareUrl" :description="shareDesc" btnText=""
        class="border-0  share-button--outline" :isBlank="false" />
      <email-button v-if="shareUrl" :url="shareUrl" :description="shareDesc" btnText=""
        class="border-0  share-button--outline" :isBlank="false" />
      <whats-app-button v-if="shareUrl" :url="shareUrl" :description="shareDesc" btnText=""
        class="border-0  share-button--outline" :isBlank="false" />
    </div>
    <div class="position-fixed bottom-0 start-0 w-100 text-center pb-3 d-md-none" style="z-index: 1001">
      <div class="btn-group shadow" role="group" aria-label="Nav Buttons">
        <button type="button" @click.prevent="visibleTab = 1" :class="btnClass(1)">
          Job Description
        </button>
        <button type="button" @click.prevent="visibleTab = 2.1" :class="btnClass(2.1)">
          My Application
        </button>
        <button type="button" @click.prevent="visibleTab = 2.2" :class="btnClass(2.2)" v-if="user && user.role && user.role.type=='nurse'">
          Requirements
        </button>
      </div>
    </div>

    <div class="row pt-2 jobDetailsContainer">
      <div :class="detailsContainerVisible + ' col-md-7 pt-2 bg-white'">
        <JobExtendedDetails :item="item" :description="jobDescriptionTemplate" v-if="isLoaded" />
      </div>




      <div :class="profileContainerVisible + ' col-md-5 requirements_col2'">

        <div :class="profileMainContainerVisible + ' p-3 bg-white'"
          v-if="user && user.role && user.role.type == 'agency_admin' && user.role.agencyId == item.agencyId">
          <router-link :to="'/agency/addajob/' + jobId" class="links mt-2">Edit This Job</router-link><br />
          <router-link :to="'/agency/createlink/' + jobId" class="links mt-2">Create Marketing Link</router-link>

          <div class='mt-3' v-if="
            user &&
            user.role &&
            (user.role.type == 'agency_admin' ||
              user.role.type == 'recruiter') &&
            user.role.agencyId == item.agencyId
          ">
            <ApplicationList :jobId="jobId" />
          </div>
        </div>






        <div :class="profileMainContainerVisible + ' bg-white status' + application.jobStatus">
          <div class="p-3 mb-3 " v-if="
            user &&
            user.role &&
            user.role.type == 'nurse' &&
            applicationExists
          ">
            You applied for this job on
            <span class="job-desc-highlight">{{
                formatTimestamp(application.applicationDate)
            }}</span>. Current status is
            <span class="job-desc-highlight">{{ application.status }}</span>.
          </div>

          <div class="mb-3" v-if="!user.loggedIn">
            <LoginBox :stayOnPage="true" />
          </div>
        </div>

        <div :class="profileMainContainerVisible">
          <div class="bg-white mb-3 p-3 border-secondary border" v-if="
            user &&
            user.role &&
            user.role.type == 'nurse' &&
            applicationExists
          ">
            <div class="text-success" style="color:darkgreen" v-if="!application.notification.recruiterFirstResponse">
              <p><b>Thank you for your interest in this position.</b> You can begin the conversation below, otherwise
                your recruiter will reach out ot you shortly. Though most recruiters will get back to you sooner, your
                recruiter should reach out to you in 24 hours.</p>
              <p>To help get you started in the meantime, please double check to see if you have your full profile
                completed and all your certifications are up to date and loaded in the system. The more prepared you
                are, the easier it will be for your recruiter to get you considered ASAP. We look forward to connecting
                with you.</p>
            </div>

            <div class="mt-2">
              Chat with the recruiter below:
              <br />
              <ChatBox :jobId="jobId" :applicantId="application.nurse"></ChatBox>
            </div>

          </div>
        </div>

      

        <div :class="requirementsContainerVisible + ' bg-white requirements'"
          v-if="!user || !user.role || user.role.type == 'nurse'">
          <template v-if="user.loggedIn">
            <div>
              <ProfilePercentage v-if="profileVisible" />
              <router-link to="/profile"
                v-if="user.loggedIn && (!user.profile || !user.profile.status || !user.profile.status.canApply)"
                class="m-3 mt-0 btn btn-sm btn-info">Edit your Profile</router-link>
            </div>


            <div class="bg-white mb-3" v-if="
              hasInvite &&
              (!user ||
                !user.role ||
                (user.role.type == 'nurse' && !applicationExists))
            ">
              <div class="d-flex bd-highlight">
                <div class="p-2 bd-highlight">
                  <ProfileImage :id="recruiterId" width="100"></ProfileImage>
                </div>
                <div class="p-2 flex-fill bd-highlight">
                  A message from your recruiter:

                  <div v-html="invite.description" class="mb-3 mt-3"></div>
                  -<b>{{ recruiterName }}</b> from
                  <b>{{ item.agencyName }}</b>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div :class="requirementsContainerVisible + ' bg-white p-3 requirements'"
          v-if="!user || !user.role || user.role.type == 'nurse'">
          <div class="" ref="requirementsList">
            <h5 class="mb-3">Application Requirements</h5>

            <div class="" itemprop="experienceRequirements">
              <div :class="'pb-2 ' + monthsInDisciplineCheck" v-if="item.monthsInDiscipline > 0">
                <template v-if="item.monthsInDiscipline > 1"><span class="job-desc-highlight">{{ item.monthsInDiscipline
                }} months</span>
                  of</template>
                Experience in
                <span class="job-desc-highlight">{{ item.discipline }}</span>
              </div>

              <div :class="'pb-2 ' + monthsInSpecialtyCheck" v-if="item.monthsInSpecialty > 0">
                <template v-if="item.monthsInSpecialty > 1"><span class="job-desc-highlight">{{ item.monthsInSpecialty
                }}
                    months</span>
                  of</template>
                Experience in
                <span class="job-desc-highlight">{{ item.specialty.label }}</span>
              </div>
            </div>
            <div itemprop="qualifications">
              <div id="licensesContainer" :class="'pb-2 ' + licenseFound" v-if="item.licensedLocations">
                Having one of the following licenses:
                <li :class="checkLicenseState(i)" v-for="i in item.licensedLocations" :key="i">
                  {{ i }}
                </li>
              </div>

              <div class="pb-2" v-if="item.certs">
                Having the following certifications:
                <li :class="checkCertifications(j.name)" v-for="j of item.certs" :key="j.name">
                  {{ j.label }}
                </li>
              </div>
            </div>
            <div id="degreesContainer" :class="'pb-2 ' + degreesFound" v-if="item.degrees"
              itemprop="educationRequirements">
              Having one of the following degrees:
              <li :class="checkDegrees(i)" v-for="i in item.degrees" :key="i">
                {{ i }}
              </li>
            </div>
          </div>

          <template v-if="!applicationExists">
            <div v-if="user.loggedIn && errors.length > 0">
              <p>Apply for this job now in
                one click! {{ item.agencyName }} will
                receive your application and contact you on next steps.</p>
              <button class="btn  btn-apply btn-apply-big hover-up btn-primary btn" @click.prevent="applyNow">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"
                  v-if="applyingNow"></span>
                <i class="bi bi-check2-circle" v-if="!applyingNow"></i>
                Apply Now</button>
            </div>
            <div v-if="user.loggedIn && errors.length == 0">
              <b>Looks like you're a perfect fit.</b> Apply for this job now in
              one click! {{ item.agencyName }} will
              receive your application and contact you on next steps.
            </div>
            <div v-if="!user.loggedIn">
              Apply for this job now in one click!
              {{ item.agencyName }} will receive your application and contact you
              on next steps.
            </div>
          </template>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { mapGetters } from "vuex";

import moment from "moment";

import { useToast } from "vue-toastification";

import ChatBox from "./molecules/ChatBox.vue";
import ApplicationList from "./molecules/ApplicationList.vue";
import JobShortDetails from "./molecules/JobShortDetails.vue";

import ProfileImage from "./molecules/ProfileImageSmall.vue";

import JobExtendedDetails from "./molecules/JobDetails.vue";

import ProfilePercentage from "./molecules/ProfilePercentageCollapsed.vue";

import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import EmailButton from "vue-share-buttons/src/components/EmailButton";
import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";

import { getJobUrl } from "../helpers/urls";

export default {
  components: {
    ChatBox,
    ApplicationList,
    JobShortDetails,
    ProfileImage,
    JobExtendedDetails,
    ProfilePercentage,
    FacebookButton,
    TwitterButton,
    WhatsAppButton,
    EmailButton
  },

  async created() {

    if (!this.$route.query.q) {
      this.$route.query.q = "";
    }

    this.jobId = this.$route.params.id;

    const firestore = firebase.firestore();

    var doc = await firestore
      .collection("jobs")
      .doc(this.$route.params.id)
      .get();

    this.jobId = doc.id;
    console.log("current data:", doc.data(), this);

    if (this.user.role.type == "nurse") this.checkApplicationStatus();

    var newData = doc.data();

    newData.taxableSalary = Math.round(newData.taxableSalary);
    newData.stipend = Math.round(newData.stipend);

    this.item = newData;
    this.recruiterId = newData.recruiterId;
    this.recruiterName = newData.recruiterName;
    this.isLoaded = true;


    this.shareUrl = getJobUrl(this.item.semantic, this.item['facilityLocation.disp'], this.item.title, false, "https://www.refinedhealthpros.com");
    this.shareDesc = this.item.title + " paying :$" + this.item.totalPay + " in " + this.item['facilityLocation.disp'];
    document.title = this.shareDesc + " | Refined Health Pros";

    var agencyDoc = await firestore
      .collection("agencies")
      .doc(newData.agencyId)
      .get()

    var agencyData = agencyDoc.data();
    this.jobDescriptionTemplate = agencyData.templates.jobDescription;


    this.applicationCheck();


    /*
    if (this.$route.params.invite != "") {
      firebase
        .firestore()
        .collection("jobs")
        .doc(this.jobId)
        .collection("inviteLinks")
        .doc(this.$route.params.invite)
        .get()
        .then((doc) => {
          var invite = doc.data();
          this.recruiterId = invite.recruiterId;
          this.recruiterName = invite.recruiterName;
          console.log("INVITE", invite, this.$route.params.invite);
          this.invite = invite;
          this.hasInvite = true;
        });
    }
    */

  },

  watch: {
    'user.loggedIn': {
      handler: function () {
        if (this.user && this.user.loggedIn) {
          this.checkApplicationStatus();
        }
      },
    },
  },

  data() {
    return {
      item: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        primarySpecialty: "",
        primarySpecActivity: "",
        primaryYearsExperience: "",
        secondarySpecialty: "",
        secondarySpecActivity: "",
        secondaryYearsExperience: "",
        activeCertifications: [],
        hitsOnLicense: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        mostInterestedIn: "",
        firstAssignment: "",
        licensedLocations: [],
      },
      updateKey: 1,
      recruiterId: "",
      recruiterName: "",
      showRequirements: false,
      jobDescriptionTemplate: "",
      errors: [],
      isLoaded: false,
      degreesFound: "",
      licenseFound: "",
      applicationExists: false,
      hasInvite: false,
      applyingNow: false,
      newApplication: false,
      profileVisible: false,
      visibleTab: 1,
      sectionEditName: "",
      sectionEditDocId: false,
      shareUrl: "",
      shareDesc: "",
      application: {jobStatus: "" }
    };
  },
  methods: {

    clickMe(item) {
      var url = getJobUrl(item.semantic, item['facilityLocation.disp'], item.title);
      this.$router.push({ path: url });
    },

    btnClass(num) {
      return num == this.visibleTab ? "btn btn-primary tabButtonsActive" : "btn btn-accent3 tabButtons";
    },

    toggleProfileVisibility() {
      this.profileVisible = !this.profileVisible;
    },

    checkLicenseState(state) {
      if (
        this.user.loggedIn &&
        this.user.role.type == "nurse" &&
        this.user.profile &&
        this.user.profile.basicInfo.licensedLocations.includes(state)
      ) {
        return "checked job-desc-highlight";
      } else {
        return "job-desc-highlight";
      }
    },
    checkCertifications(cert) {
      if (!this.user.loggedIn || this.user.role.type != "nurse")
        return "job-desc-highlight";
      if (
        this.user.loggedIn &&
        this.user.profile &&
        this.user.profile.basicInfo.activeCertifications.some(
          (item) => item.name == cert
        )
      ) {
        return "checked job-desc-highlight";
      } else {
        return "job-desc-highlight missed";
      }
    },
    checkDegrees(degree) {
      if (
        this.user.loggedIn &&
        this.user.role.type == "nurse" &&
        this.user.profile &&
        this.user.profile.educationSummary.degrees.includes(degree)
      ) {
        return "checked job-desc-highlight";
      } else {
        return "job-desc-highlight";
      }
    },

    applicationCheck() {
      if (!this.user.loggedIn || this.user.role.type != "nurse") return;

      this.errors = [];
      if (this.item.monthsInDiscipline > 0) {
        if (
          !this.user.profile ||
          !this.user.profile.experienceSummary ||
          !this.user.profile.experienceSummary.disciplines.RN ||
          this.user.profile.experienceSummary.disciplines.RN.totalExperience <
          this.item.monthsInDiscipline
        )
          this.errors.push(
            "You don't have enough experience in the Discipline (" +
            this.item.discipline +
            ")."
          );
      }

      if (
        !this.user.profile ||
        !this.user.profile.experienceSummary ||
        !this.user.profile.experienceSummary.disciplines.RN ||
        !this.user.profile.experienceSummary.disciplines.RN.specialties[
        this.item.specialty.label
        ] ||
        this.user.profile.experienceSummary.disciplines.RN.specialties[
          this.item.specialty.label
        ].totalExperience < this.item.monthsInSpecialty
      )
        this.errors.push(
          "You don't have enough experience in the Specialty (" +
          this.item.specialty.label +
          ")."
        );

      if (
        this.item.licensedLocations &&
        this.item.licensedLocations.length > 0
      ) {
        var licensed = this.item.licensedLocations.filter((value) =>
          this.user.profile.basicInfo.licensedLocations.includes(value)
        );
        console.log("LICENSE INTERSECTION", licensed);
        if (licensed.length == 0) {
          this.licenseFound = "notFound";
          this.errors.push(
            "You must be licensed in atleast one of the following: " +
            this.item.licensedLocations.join(", ")
          );
        }
      }
      if (
        this.item.activeCertifications &&
        this.item.activeCertifications.length > 0
      ) {
        var certifications = this.item.activeCertifications.filter((value) =>
          this.user.profile.basicInfo.activeCertifications.some(
            (item) => item.label == value
          )
        );
        console.log("cert INTERSECTION", certifications);
        if (certifications.length != this.item.activeCertifications.length) {
          this.errors.push(
            "You must have all the following certifications: " +
            this.item.activeCertifications.join(", ")
          );
        }
      }

      if (this.item.degrees && this.item.degrees.length > 0) {
        var degrees = this.item.degrees.filter((value) =>
          this.user.profile.educationSummary.degrees.includes(value)
        );
        console.log("degrees INTERSECTION", degrees);
        if (degrees.length == 0) {
          this.degreesFound = "notFound";
          this.errors.push(
            "You must have one of the following degrees: " +
            this.item.degrees.join(", ")
          );
        }
      }
    },

    applyNow() {

      if (!this.user.loggedIn) {
        useToast().error("You must login first. Click here to login now.", { onClick: () => document.getElementById("LoginButton").click() });
        return;
      }

      if (!this.user.profile.status.canApply || !this.user.isVerified) {
        useToast().error("Please fill in your profile to apply to this position.  Click here to edit your profile.", { onClick: () => this.$router.push("/profile") });
        return;
      }

      this.applyingNow = true;
      const apply = firebase
        .app()
        .functions("us-central1")
        .httpsCallable("apply-applyNow");
      console.log({
        jobId: this.jobId,
      });
      apply({
        jobId: this.jobId,
      }).then((data) => {
        console.log("apply response", data);
        this.newApplication = true;
        this.checkApplicationStatus();
        this.applyingNow = false;
        this.profileVisible = false;
        useToast().success("Your application has been submitted");
        this.$gtag.event('JobApplication', { jobId: this.jobId });
      });
    },

    checkApplicationStatus() {
      if (!this.user.loggedIn) {
        this.applicationExists = false;
        return;
      }
      firebase
        .firestore()
        .collection("jobs")
        .doc(this.jobId)
        .collection("applications")
        .doc(this.user.data.uid)
        .get()
        .then((doc) => {
          console.log("APPCHECK2", doc.data());
          if (doc.exists) {
            // application found.
            this.application = doc.data();
            this.applicationExists = true;
            if (!this.application.jobStatus) {
              this.application.jobStatus = "";
            }
          } else {
            this.profileVisible = true;
          }
        })
        .catch(() => {
          // doc doesn't exist
          this.profileVisible = true;
        });
    },

    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => { });
    },

    formatTimestamp(ts) {
      return moment(new Date(ts)).format("MM/DD/YYYY");
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),

    detailsContainerVisible() {
      return this.$isMobile() && this.visibleTab != 1 ? "d-none" : "";
    },

    profileContainerVisible() {
      return this.$isMobile() && Math.floor(this.visibleTab) != 2
        ? "d-none"
        : "";
    },

    profileMainContainerVisible() {
      return this.$isMobile() && this.visibleTab != 2.1 ? "d-none" : "";
    },

    requirementsContainerVisible() {
      return this.$isMobile() && this.visibleTab != 2.2 ? "d-none" : "";
    },

    hasApplied() {
      return this.applicationExists;
    },

    licenseCheck() {
      return "";
    },

    showProfileLabel() {
      if (this.profileVisible) {
        return "Hide Profile";
      } else {
        return "Show Profile";
      }
    },
    monthsInDisciplineCheck() {
      if (!this.user.loggedIn) return "";

      if (
        this.user &&
        this.user.profile &&
        this.user.profile.experienceSummary &&
        this.user.profile.experienceSummary.disciplines.RN
      ) {
        if (
          this.user.profile.experienceSummary.disciplines.RN.totalExperience >=
          this.item.monthsInDiscipline
        )
          return "checked";
      }
      return "missed";
    },

    monthsInSpecialtyCheck() {
      if (!this.user.loggedIn) return "";

      if (
        this.user &&
        this.user.profile &&
        this.user.profile.experienceSummary &&
        this.user.profile.experienceSummary.disciplines.RN &&
        this.user.profile.experienceSummary.disciplines.RN.specialties[
        this.item.specialty.label
        ]
      ) {
        if (
          this.user.profile.experienceSummary.disciplines.RN.specialties[
            this.item.specialty.label
          ].totalExperience >= this.item.monthsInSpecialty
        ) {
          console.log(
            "SPECIALTY",
            this.user.profile.experienceSummary.disciplines.RN.specialties[
            this.item.specialty.label
            ]
          );
          return "checked";
        }
      }
      return "missed";
    },
    displayNameComputed() {
      if (
        this.user.profile &&
        this.user.profile.basicInfo &&
        this.user.profile.basicInfo.firstName &&
        this.user.profile.basicInfo.lastName
      ) {
        return (
          this.user.profile.basicInfo.firstName +
          " " +
          this.user.profile.basicInfo.lastName
        );
      } else if (this.user.profile && this.user.profile.name) {
        return this.user.profile.name;
      } else if (this.user.data && this.user.data.displayName) {
        return this.user.data.displayName;
      } else {
        return "";
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css">

</style>
<style scoped>
.tabButtons {
  font-size: .8rem;
}

.tabButtonsActive {
  font-weight: bold;
}

.job-description-bullets {}

.job-description-bullets LI {}

.sm-profile-photo {
  max-width: 50px;
  max-height: 50px;
}

.checked {
  color: green;
}

.checked::after {
  content: " \2713\2713\2713";
  font-weight: 900;
}

#degreesContainer.notFound LI::after,
#licensedsContainer.notFound LI::after {
  content: " ⚠";
}

#degreesContainer.notFound,
#licensedsContainer.notFound {}

.missed::after {
  content: " ⚠";
  font-weight: normal;
}

.missed {}

.requirements>div {
  margin-bottom: 1rem;
}

.payInfo {
  background: #ffffed;
}
</style>

<style>

</style>