<template>

    <div class="container d-none d-md-block">

        <footer class="py-5">
            <div class="row">
                <div class="col-6 col-md-4 mb-3">
                        <router-link to="/" class="navbar-brand mb-3 d-block ms-4">
                            <span style="font-family: 'Ms Madi'; font-weight: bold; font-size: 2rem;">Refined</span> &nbsp;
                            <span style="font-family: Montserrat;">Health Pros</span>
                        </router-link>

                    <ul class="list-unstyled d-flex socialLinks">
                    <li class="ms-3"><a class="link-dark" href="https://twitter.com/RefinedHeath" target="_blank"><i
                                class="bi bi-twitter"></i></a></li>
                    <li class="ms-3"><a class="link-dark" href="https://www.instagram.com/refinedhealthpros/"
                            target="_blank"><i class="bi bi-instagram"></i></a></li>
                    <li class="ms-3"><a class="link-dark" href="https://www.facebook.com/groups/TravelRNJobGroup"
                            target="_blank"><i class="bi bi-facebook"></i></a></li>
                    <li class="ms-3"><a class="link-dark" href="https://www.linkedin.com/company/refined-health-pros/"
                            target="_blank"><i class="bi bi-linkedin"></i></a></li>
                </ul>
                </div>

                <div class="col-6 col-md-4 mb-3 d-flex flex-row">
                    <ul class="nav flex-column me-3">
                        <li class="nav-item mb-2"><router-link to="/" class="nav-link p-0 text-muted">Home</router-link>
                        </li>
                        <li class="nav-item mb-2"><router-link to="/pages/aboutus" class="nav-link p-0 text-muted">About
                                Us</router-link></li>
                        <li class="nav-item mb-2"><router-link to="/pages/partnerships"
                                class="nav-link p-0 text-muted">Partnerships</router-link></li>
                        <li class="nav-item mb-2"><router-link to="/pages/faqs"
                                class="nav-link p-0 text-muted">FAQs</router-link></li>
                    </ul>

                    <ul class="nav flex-column ms-3">
                        <li class="nav-item mb-2"><router-link to="/pages/privacy"
                                class="nav-link p-0 text-muted">Privacy Policy</router-link></li>
                        <li class="nav-item mb-2"><router-link to="/pages/terms" class="nav-link p-0 text-muted">Terms
                                of Use</router-link></li>
                        <li class="nav-item mb-2"><router-link to="/pages/copyrightpolicy"
                                class="nav-link p-0 text-muted">Copyright Policy</router-link></li>
                        <li class="nav-item mb-2"><router-link to="/pages/optout" class="nav-link p-0 text-muted">Opt
                                out</router-link></li>

                    </ul>
                </div>

                <div class="col-md-4  mb-3">
                    <form>
                        <h5>Subscribe to our newsletter</h5>
                        <p>Keep up with whats new and exiting from us.</p>
                        <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                            <label for="newsletter1" class="visually-hidden">Email address</label>
                            <input id="newsletter1" type="email" class="form-control" placeholder="Email address"
                                v-model="email">
                            <button @click.prevent="subscribe" class="btn btn-primary" type="button">Subscribe</button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>© {{ new Date().getFullYear() }} Refined Health Pros, Inc. All rights reserved.</p> 
            </div>
        </footer>
    </div>
    
</template>

   
<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useToast } from 'vue-toastification';


export default {

    data() {
        return {
            email: "",
        };
    },

    methods: {
        subscribe() {
            console.log("IN SUBSCRIBE");
            const sub = firebase
                .app()
                .functions("us-central1")
                .httpsCallable("emails-subscribeNewsletter");

            sub({
                email: this.email,
            }).then((data) => {
                console.log("ALL DONE", data);
                useToast().success("Thank you for subscribing");
                this.email = "";
                this.$gtag.event('NewsletterSubscription');
            });
        }
    }


};

</script>
<style>
.socialLinks a .bi {

    padding: 10px;
    border-radius: 50%;

    font-size:1.5rem;
}
</style>