<template>


    <!--<h3 class="mb-3 mt-3">Job Description</h3>-->


    <div class="job-overview mt-3 shadow-sm">
        <h5 class="border-bottom pb-15 mb-30">Employment Information</h5>
        <div class="row">
            <div class="col-lg-6 d-flex">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/industry.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span
                        class="text-description industry-icon mb-10">Specialty</span><span class="job-desc-highlight"
                        v-if="item.specialty">{{
                                item.specialty.label
                        }}</span></div>
            </div>
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/job-level.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span
                        class="text-description joblevel-icon mb-10">Discipline</span><span
                        class="job-desc-highlight">{{
                                item.discipline
                        }}</span></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/salary.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span class="text-description salary-icon mb-10">Hours</span><span
                        class="job-desc-highlight" itemprop="workHours">{{ item.hoursPerShift }}</span>&nbsp;hours per
                    shift</div>
            </div>
            <div class="col-lg-6 d-flex">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/experience.svg" alt="jobBox">
                </div>
                <div class="sidebar-text-info ms-2"><span
                        class="text-description experience-icon mb-10">Shift</span><span class="job-desc-highlight">{{
                                item.shiftType
                        }}</span></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/job-type.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span class="text-description jobtype-icon mb-10">Shifts</span>
                    <span v-if="isNaN(item.shiftsPerWeek) || item.shiftsPerWeek == 'Per Diem'"><span
                        class="job-desc-highlight" >Per Diem</span></span>
                    <span v-else><span
                        class="job-desc-highlight" >{{ item.shiftsPerWeek }}</span>&nbsp;shifts per week</span></div>
            </div>
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/deadline.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span class="text-description mb-10">Start Date</span><span
                        class="job-desc-highlight" itemprop="jobStartDate">{{ startDateFormatted }}</span></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/updated.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span
                        class="text-description jobtype-icon mb-10">Duration</span><span class="job-desc-highlight">{{
                                item.duration
                        }}</span>&nbsp;weeks</div>
            </div>
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/location.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span class="text-description mb-10">Setting</span><span
                        class="job-desc-highlight">
                    
                        <span v-if="isNaN(item.shiftsPerWeek) || item.shiftsPerWeek == 'Per Diem'">Per Diem</span>
                    <span v-else>Travel</span>
                    
                    </span></div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/deadline.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span class="text-description jobtype-icon mb-10">Reference
                        #</span><span class="job-desc-highlight">{{
                                item.jobId
                        }}</span></div>
            </div>
            <div class="col-lg-6 d-flex mt-1">
                <div class="sidebar-icon-item"><img src="/assets/imgs/page/job-single/deadline.svg" alt="jobBox"></div>
                <div class="sidebar-text-info ms-2"><span class="text-description jobtype-icon mb-10">Job
                        Status</span><span class="job-desc-highlight">{{
                                item.status
                        }}</span></div>
            </div>

        </div>
    </div>

    <div class="pb-3 payInfo" >
        <div v-if="item.stipend > 0">
            <span class="job-desc-highlight">${{ item.taxableSalary }}</span> in weekly taxable income<br />
            <span class="job-desc-highlight">${{ item.stipend }}</span> in non-taxable stipend
        </div>
        <div class="mt-1 text-lg border-top"><span class="job-desc-highlight">${{ item.totalPay }}</span> Gross 
            
            <span v-if="isNaN(item.shiftsPerWeek) || item.shiftsPerWeek == 'Per Diem'">Per Day</span>
            <span v-else>Weekly</span>

        </div>
    </div>

    <div v-html="jobDescription" itemprop="description"></div>

    <div itemprop="url" class="d-none">{{ clickMe(item) }}</div>
    <span itemprop="datePosted" class="d-none">{{ formatTimestamp2(item.dateAdded) }}</span>



</template>


<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
import { getJobUrl } from "../../helpers/urls";

export default {
    props: ['item', 'description'],

    methods: {

        viewed() {
            console.log(this.item);
            var view = {}
            view.specialty = this.item.specialty.label;
            view.discipline = this.item.discipline;
            view.location = this.item['facilityLocation.disp'];
            view.jobId = this.item.semantic;
            view.agencyId = this.item.agencyId;

            const viewJob = firebase
                .app()
                .functions("us-central1")
                .httpsCallable("apply-viewed");

            console.log("VIEWED", view);
            viewJob(view).catch(() => { 
                // silently fail...
            });
        },

        clickMe(item) {
            var url = getJobUrl(item.semantic, item['facilityLocation.disp'], item.title, false, "https://www.refinedhealthpros.com");
            return url;
        },

        formatTimestamp(ts) {
            return moment(new Date(ts)).format("MMM YYYY");
        },

        formatTimestamp2(ts) {
            return moment(new Date(ts)).format("YYYY-MM-DD");
        },


    },

    mounted() {
        this.viewed();
    },

    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        }),

        startDateFormatted() {
            if (!this.item || !this.item.startDate)
                return "";
            return moment(this.item.startDate).format('MM/DD/YYYY');
        },

        jobDescription() {

            if (!this.item.discipline)
                return "";

            //var shiftBreakdown = this.item.shiftBreakdown.split("x")
            var num_shifts = this.item.shiftsPerWeek * 1;
            var hours_per_shift = this.item.hoursPerShift * 1;
            var hours_per_week = this.item.shiftsPerWeek * this.item.hoursPerShift;

            var desc = this.description;

            var replacements = {
                WEEKLY_GROSS: this.item.totalPay,
                CITY: this.item['facilityLocation.city'],
                STATE: this.item['facilityLocation.state'],
                DISCIPLINE: this.item.discipline,
                SPECIALTY: this.item.specialty.label,
                WEEKS: this.item.duration,
                HOURS_PER_DAY: hours_per_shift,
                HOURS_PER_WEEK: hours_per_week,
                START_DATE: moment(this.item.startDate).format('MM/DD/YYYY'),
                NUM_SHIFTS: num_shifts
            }

            for (var i in replacements) {
                desc = desc.replaceAll("[" + i + "]", replacements[i]);
            }

            return desc;
        }
    },
}
</script>
