
<template>

    <table class="table table-hover table-sm">
        <thead>
            <tr>
                <th>&nbsp;</th>
                <th>Applicant</th>
                <th>Date</th>
                <th>Status</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <template v-for="application in applications" :key="application.nurse">
            <tr >
                <td><img :src="application.photo" class="rounded-circle profile-photo" /></td>
                <td>{{application.nurseName}}</td>
                <td>{{formatTimestamp(application.applicationDate)}}</td>
                <td>{{application.status}}</td>
                <td><router-link :to="{ name: 'View Application', params: { jobId: this.jobId, id: application.docId }}">Details</router-link></td>
            </tr>
            
        </template>
        </tbody>
        </table>
        

</template>
















<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';






export default {
    components: {},

    created() {
        console.log("JOBID", this.jobId, this.user.data.uid);

        var snapshot = null;
        if (this.user.role.type=="agency_admin") {
            snapshot = firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").where("agencyId", "==", this.user.role.agencyId);
        } else if (this.user.role.type=="recruiter") {
            snapshot = firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").where("recruiterId", "==", this.user.data.uid).where("agencyId", "==", this.user.role.agencyId);
        } else {
            snapshot = firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").where("nurse", "==", this.user.data.uid);
        }


        this.snapshot = snapshot.get().then((snapshot) => {

            console.log("SNAPSHOT", snapshot);
                //first time load
                this.applications = snapshot.docs.map(doc => {
                    var data = doc.data();
                    data.docId = doc.id;
                    return data;
                });
                

        }).catch((e) => {
            console.error("ERROR", e)
        }).finally(() => {
            this.loaded = true;
        });
    },

    data() {
        return {
            loaded: false,
            applications: []
        }
    },
    props: ['jobId'],

    methods: {
        formatTimestamp(ts) {
            return moment(new Date(ts)).format("MM/DD/YYYY h:mm a");
        },


    },

    computed: {
        ...mapGetters({
            user: "user",
            agency: "agency"
        })
    }
}
</script>
<style scoped>
    .profile-photo {
        max-width: 30px;
        max-height: 3 0px;
        object-fit: cover;
        margin-top: .2rem;
    }
    
</style>