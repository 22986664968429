<template>
  <div>
    <ais-instant-search
      :search-client="searchClient"
      index-name="jobs"
      :routing="routing"
      :onStateChange="stateChanged"
      :initial-ui-state="initialUiState"
      :search-function="searchFunction"
      
    >
      <ais-configure v-bind="searchParams" />

      <div class="row">
        <div class="col-12 bg-accent3 p-3 shadow-sm mb-3">
          <div class="topFilters">
            <ais-panel class="dropdown">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Pay Range
              </button>

              <ais-range-input attribute="totalPay" class="dropdown-menu p-3">
                <template
                  v-slot="{ currentRefinement, range, canRefine, refine }"
                  >Weekly Pay:<br />
                  $<input
                    type="number"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="range.min"
                    :disabled="!canRefine"
                    :value="formatMinValue(currentRefinement.min, range.min)"
                    @input="
                      refine({
                        min: $event.currentTarget.value,
                        max: formatMaxValue(currentRefinement.max, range.max),
                      })
                    "
                  />
                  to: $<input
                    type="number"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="range.max"
                    :disabled="!canRefine"
                    :value="formatMaxValue(currentRefinement.max, range.max)"
                    @input="
                      refine({
                        min: formatMinValue(currentRefinement.min, range.min),
                        max: $event.currentTarget.value,
                      })
                    "
                  />
                </template>
              </ais-range-input>
            </ais-panel>

            <ais-panel class="dropdown moreFilters">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Discipline
              </button>
              <ais-refinement-list
                attribute="discipline"
                class="dropdown-menu p-3"
              />
            </ais-panel>

            <ais-panel class="dropdown">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Specialty
              </button>
              <ais-refinement-list
                attribute="specialty.label"
                searchable
                class="dropdown-menu p-3"
                searchable-placeholder="Search specialties"
                :limit="40"
                :class-names="{
                  'ais-RefinementList-list': 'specialties-list',
                }"
              />
            </ais-panel>

            <ais-panel class="dropdown moreFilters">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Facility
              </button>
              <ais-refinement-list
                attribute="facilityName"
                searchable
                class="dropdown-menu p-3"
                searchable-placeholder="Search facilities"
              />
            </ais-panel>

            <ais-panel class="dropdown moreFilters">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Staffing Agency
              </button>
              <ais-refinement-list
                attribute="agencyName"
                class="dropdown-menu p-3"
              />
            </ais-panel>

            <ais-panel class="dropdown moreFilters">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Shift
              </button>
              <ais-refinement-list
                attribute="shiftType"
                class="dropdown-menu p-3"
              />
            </ais-panel>

            <ais-panel class="dropdown moreFilters">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Hours
              </button>
              <ais-refinement-list
                attribute="hoursPerShift"
                class="dropdown-menu p-3"
              />
            </ais-panel>

            <ais-panel class="dropdown moreFilters">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Duration
              </button>
              <ais-refinement-list
                attribute="duration"
                class="dropdown-menu p-3"
                :transform-items="addWeeksLabel"
              >
              </ais-refinement-list>
            </ais-panel>

            <ais-panel class="dropdown">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                State
              </button>
              <ais-refinement-list
                attribute="licensedLocations"
                searchable
                class="dropdown-menu p-3"
                searchable-placeholder="Search states"
                :limit="50"
                :class-names="{
                  'ais-RefinementList-list': 'specialties-list',
                }"
              />
            </ais-panel>
            <ais-panel class="dropdown">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                City
              </button>
              <ais-refinement-list
                attribute="facilityLocation.disp"
                searchable
                class="dropdown-menu p-3"
                searchable-placeholder="Search cities"
              />
            </ais-panel>
            <ais-panel class="dropdown">
              <button
                class="btn dropdown-toggle btn-outline btn-sm"
                data-bs-toggle="dropdown"
              >
                Benefits
              </button>
              <ais-refinement-list
                attribute="benefits"
                searchable
                class="dropdown-menu p-3"
                searchable-placeholder="Search benefits"
              />
            </ais-panel>
          </div>
          <ais-search-box
            placeholder="Search by anything... specialty, city, state, shift, etc."
          />
        </div>
      </div>
      <div class="row mb-2 p-1">
        <div class="col-md-6 mb-1 align-items-center">
          <ais-current-refinements
            :class-names="{
              'ais-CurrentRefinements-item':
                'badge bg-secondary border text-black me-2',
            }"
          >
            <template v-slot="{ items, refine, createURL }">
              <span
                v-for="item in items"
                :key="item.attribute"
                class="badge bg-warning border text-black me-2"
              >
                <span v-if="item.label != 'status'"
                  >{{ refinementLabels[item.label] }}

                  <span
                    class="badge bg-primary refinement_badge me-2"
                    v-for="refinement in item.refinements"
                    :key="
                      [
                        refinement.attribute,
                        refinement.type,
                        refinement.value,
                        refinement.operator,
                      ].join(':')
                    "
                  >
                    <a
                      :href="createURL(refinement)"
                      @click.prevent="refine(refinement)"
                    >
                      {{ refinement.label }} X
                    </a>
                  </span>
                </span>
              </span>
              <ais-clear-refinements class="" v-if="items.length > 1"
                ><template v-slot="{ canRefine, refine, createURL }">
                  <a
                    :href="createURL()"
                    @click.prevent="refine"
                    v-if="canRefine"
                    class="badge bg-danger text-white"
                  >
                    Clear all filters
                  </a>
                </template></ais-clear-refinements
              >
            </template>
          </ais-current-refinements>
        </div>
        <div class="col-md-6 align-items-center">
          <div class="sort-by float-end">
            <ais-sort-by
              :items="[
                { label: 'Recommended', value: 'jobs' },
                { label: 'Start Date', value: 'jobs/sort/startDate:desc' },
                { label: 'Date Posted', value: 'jobs/sort/dateAdded:desc' },

                {
                  label: 'Weekly Pay (desc)',
                  value: 'jobs/sort/totalPay:desc',
                },
                { label: 'Weekly Pay (asc)', value: 'jobs/sort/totalPay:asc' },
              ]"
            >
              <template v-slot="{ items, currentRefinement, refine }">
                Sort By:
                <select
                  class="ais-SortBy-select"
                  v-model="currentSort"
                  @change="refine(currentSort)"
                  placeholder="Sort By"
                >
                  <option
                    :value="item.value"
                    :selected="currentRefinement == currentSort"
                    v-for="item of items"
                    :key="item"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </template>
            </ais-sort-by>
          </div>
          <ais-stats />
        </div>
      </div>

      <ais-state-results>
        <template v-slot="{ query, hits }">
          <p class="no-results" v-if="hits.length === 0">
            No results found matching <em>{{ query }}</em
            >.
          </p>
        </template>
      </ais-state-results>

      <ais-hits
        :class-names="{
          'ais-Hits-item': 'col col-4',
          'ais-Hits': 'row',
        }"
        :escapeHTML="false"
      >
        <template v-slot:default="{ items }">
          <div
            v-for="item in items"
            :key="item"
            class="col-md-12 border p-2 shadow-sm mb-3 bg-white jobListing"
            @click.prevent="clickMe(item)"
          >
            <div class="row p-1">
              <div
                class="col align-items-center d-md-flex d-none"
                style="flex: 0; width: 100px"
              >
                <div style="width: 120px; text-align: center">
                  <img
                    class="job-agency-logo shadow"
                    :src="
                      'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/agencies%2F' +
                      item.agencyId +
                      '%2Flogo.png?alt=media'
                    "
                    alt="Logo"
                  />
                </div>
              </div>
              <div class="col md-2 ps-3">
                <div class="job-title">
                  <ais-highlight attribute="title" :hit="item" />
                </div>
                <div class="job-location">
                  <ais-highlight attribute="facilityName" :hit="item" />
                </div>
                <div class="job-location">
                  {{ item["facilityLocation.disp"] }}
                </div>
                <div class="job-shift-breakdown">
                  <ais-highlight attribute="hoursPerShift" :hit="item" /> hours
                  <span v-if="item.type != 'Per Diem'">
                  x
                  <ais-highlight attribute="shiftsPerWeek" :hit="item" /> shifts </span>
                  x <ais-highlight attribute="duration" :hit="item" /> weeks
                  (<ais-highlight attribute="shiftType" :hit="item" />)
                </div>
                <div>
                  {{ startDateFormatted(item.startDate) }}
                </div>
                <div class="badge border border-warning text-black">
                  Posted {{ timeFromNow(item.dateAdded) }}
                </div>
              </div>
              <div
                class="col-md-2 job-pay align-items-center d-flex text-primary"
              >
                {{item.totalPay.toLocaleString("en-US", {style:"currency", currency:"USD", maximumFractionDigits:0})}}
                /&nbsp;<span v-if="item.type =='Per Diem'">day</span><span v-else>wk</span>
              </div>
              <div class="col-md-2 align-items-center d-flex position-relative">
                <button
                  class="btn btn-apply hover-up btn-primary font-montserrat"
                  style="font-weight: 500"
                >
                  <i class="bi bi-check2-circle"></i>
                  I'm Interested
                </button>

                <div
                  class="position-absolute bottom-0 end-0 pe-2 d-md-none d-block"
                  style="width: 120px; text-align: right"
                >
                  <img
                    class="logoMobile"
                    :src="
                      'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/agencies%2F' +
                      item.agencyId +
                      '%2Flogo.png?alt=media'
                    "
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </ais-hits>
      <footer>
        <ais-pagination />
      </footer>
    </ais-instant-search>
  </div>
</template>

<script>
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { history as historyRouter } from "instantsearch.js/es/lib/routers";
//import { simple as simpleMapping } from "instantsearch.js/es/lib/stateMappings";
import moment from "moment";
import { getJobUrl } from "@/helpers/urls";
import debounce from "lodash.debounce";

function googleAnalyticsMiddleware() {
  const sendEventDebounced = debounce((uiState) => {
    uiState;
    window.ga("set", "page", window.location.pathname + window.location.search);
    window.ga("send", "pageView");
  }, 3000);

  return {
    onStateChange({ uiState }) {
      sendEventDebounced(uiState);
    },
    subscribe() {},
    unsubscribe() {},
  };
}

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "7MwD0IITV2OcXRYlzMa9b6cREg9tJMOu", // Be sure to use an API key that only allows searches, in production
    nodes: [
      {
        host: "g4ip5nk9yo0txzr3p-1.a1.typesense.net",
        port: "443",
        protocol: "https",
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  //  filterBy is managed and overridden by InstantSearch.js. To set it, you want to use one of the filter widgets like refinementList or use the `configure` widget.
  additionalSearchParameters: {
    query_by:
      "title, facilityName,specialty.label,licensedLocations,facilityLocation.disp,jobId",
    filter_by: "status:=Active",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

export default {
  created() {
    document.title = "Search Jobs | Refined Health Pros";
    // get ui state.
    var state = localStorage.getItem("searchState");
    if (state !== null) {
      state = JSON.parse(state);
      if (state.jobs) {
        this.initialUiState = state;
        console.log("NEW STATE", state);
      }
    }
  },

  methods: {
    clickMe(item) {
      console.log(item);
      var url = getJobUrl(
        item.semantic,
        item["facilityLocation.disp"],
        item.title
      );
      this.$router.push({ path: url });
    },
    startDateFormatted(timestamp) {
      return moment.unix(timestamp / 1000).format("MMM DD, YYYY");
    },
    formatMinValue(minValue, minRange) {
      return minValue !== null && minValue !== minRange ? minValue : "";
    },
    formatMaxValue(maxValue, maxRange) {
      return maxValue !== null && maxValue !== maxRange ? maxValue : "";
    },
    addWeeksLabel(items) {
      return items.map((item) => ({
        ...item,
        label: item.label + " Weeks",
      }));
    },
    timeFromNow(time) {
      return moment.unix(time / 1000).fromNow();
    },
    stateChanged({ uiState, setUiState }) {
      // Custom logic
      console.log(uiState, setUiState);
      if (uiState.jobs) {
        localStorage.setItem("searchState", JSON.stringify(uiState));
      }
      setUiState(uiState);
    },
  },

  data() {
/*
    var refinements = {
      'discipline': "discipline",
      'specialty.label': "specialty",
      'facilityName': "facility",
      'agencyName': "agency",
      'recruiterName': "recruiter",
      'shiftType' : "shift",
      'hoursPerShift': "hours",
      'duration': "duration",
      'licensedLocations': "state",
      'facilityLocation.disp': "city",
      'benefits': "benefits"
    }
    var indexName = "jobs";

    */

    return {
      currentSort: "jobs",
      searchClient,
      routing: {
        router: historyRouter(/*{
          createURL({qsModule, routeState, location } ) {
            if (!routeState.hasState)
              return false;
            console.log("CreateURL1", routeState.length, qsModule, routeState, location);
            if (location.href) {
            const urlParts = location.href.match(/^(.*?)\/searchjobs/);
            const baseUrl = `${urlParts ? urlParts[1] : ''}/`;
        
            const queryParameters = {};

              if (routeState.q) {
                queryParameters.q = encodeURIComponent(routeState.q);
              }
              if (routeState.page != 1) {
                queryParameters.page = routeState.page;
              }
              if (routeState.salary) {
                queryParameters.salary = routeState.salary;
              }

              const queryString = qsModule.stringify(queryParameters, {
                addQueryPrefix: true,
                arrayFormat: 'repeat'
              });

              return `${baseUrl}searchjobs${queryString}`;
            

            }
          }
      }*/),
        /*stateMapping: {
          stateToRoute(uiState) {
            console.log("UISTATE 2", uiState);
            const indexUiState = uiState[indexName];
            var result = {
              hasState: true,
              q: indexUiState.query,
              categories: indexUiState.menu && indexUiState.menu.categories,
              salary:
                indexUiState.range &&
                indexUiState.range.totalPay, 
              page: indexUiState.page,
            };

            for (var i in indexUiState.refinementList) {
              result[refinements[i]] = indexUiState.refinementList[i].join("|");
            }
            console.log("New Route", result);
            return result;
          },
          routeToState(routeState) {

            console.log("routeState", routeState);
            var result = {
              [indexName]: {
                query: routeState.q,
                menu: {
                  categories: routeState.categories,
                },
                refinementList: {
                  
                },
                range: {
                  totalPay: routeState.salary
                },
                page: routeState.page,
              },
            };
            for (var i in refinements) {
              if (routeState[refinements[i]])
                result[indexName].refinementList[i] = routeState[refinements[i]].split("|");
            }


            return result;
          },
        },*/
      },
      middlewares: [googleAnalyticsMiddleware],
      searchParams: {
        hitsPerPage: 30,
        facets: ["status"],
      },
      searchFunction(helper) {
        const page = helper.getPage();
        helper.addFacetRefinement("status", "Active").setPage(page).search();
      },
      initialUiState: null,

      refinementLabels: {
        totalPay: "Pay",
        discipline: "Discipline",
        "specialty.label": "Specialty",
        facilityName: "Facility",
        agencyName: "Agency",
        recruiterName: "Recruiter",
        shiftType: "Shift",
        hoursPerShift: "Hours per Shift",
        duration: "Duration",
        licensedLocations: "State",
        "facilityLocation.disp": "City",
        benefits: "Benefits",
      },
    };
  },
  components: {},
};
</script>
<style scoped>
.badge {
  font-weight: normal;
}
</style>