<template>
    <div class="row">
        
        <main class="main bg-white ps-0 pe-0">
            
            <section class="section-box mt-70 mb-40">
                <div class="container">
                    <div class="row">
                        <div class="col-3">
                            <img :src="agency.logo" class="logoImage"/>
                        </div>
                        <div class="col">
                            <h1>{{ agency.name }}</h1>
                            <div v-html="agency.description"></div>
                        </div>
                    </div>
                </div>
            </section>

<hr />

            <section class="section-box mt-30  ps-2 pe-2">
                <div class="container">




                    <div class="text-center mb-3">
                        <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated"
                            style="visibility: visible; animation-name: fadeInUp;">{{agency.name}} Premier Jobs</h2>
                    </div>
                    <div class="row">


                        <swiper :slides-per-view="4" :space-between="25"     :loop="true"
:autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }" :breakpoints="{
        '0': {
        slidesPerView: 2,
        spaceBetween: 25,
      },'480': {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      '1024': {
        slidesPerView: 4,
        spaceBetween: 25,
      },
       
    }">

<swiper-slide v-for="job in topJobs" :key="job"> 
    <JobTile :job="job" />
                                </swiper-slide>
                            </swiper>


                    </div>
                </div>
            </section>

            <section class="section-box mb-30 bg-brand-2 pt-5 pb-2">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-3">
                            <div class="pt-2 ps-3">
                                <h2 class="color-white mb-20 ">Our Focus</h2>
                                <p class="color-white mb-30 pe-3">Most popular specialties offered by {{ agency.name }}</p>
                                <div class="mt-20"> <router-link class="btn btn-brand-1 btn-icon-more btn-lg hover-up btn-warning " style="padding-right: 50px;" to="/searchjobs">Explore</router-link></div>
                            </div>
                        </div>
                        <div class="col-xl-9 pt-4 pt-md-0">
                            <swiper :slides-per-view="4" :space-between="25"     :loop="true"
:autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :breakpoints="{
        '0': {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      '480': {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      '1024': {
        slidesPerView: 5,
        spaceBetween: 25,
      },
      
    }">

                                <swiper-slide v-for="(item, index) in this.topSpecialties" :key="item.value">
                                    <div class="card-grid-5 card-category hover-up"
                                        :style="'background-image: url(' + getSpecialtyImage(item.value) + '); background-position: center bottom'">  
                                        <router-link :to="'/searchjobs?jobs[query]=' + this.topSpecialtiesLabels[index].value + '&jobs[refinementList][agencyName][0]=' + agency.name">
                                            <div class="box-cover-img"> 
                                                <div class="content-bottom">
                                                    <h6 class="color-white mb-5">{{topSpecialtiesLabels[index].value}}</h6>
                                                    <p class="color-white font-xs"><span>{{item.count}}</span><span> Jobs
                                                            Available</span></p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </swiper-slide>
                                
                            </swiper>
                        </div>

                    </div>

                </div>
            </section>

            <section class="section-box mt-50" v-if="this.topCities.length > 0">
        <div class="container">
          <div class="text-center">
            <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">Jobs by Location</h2>
            <p class="font-lg color-text-paragraph-2 wow animate__ animate__fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">Most popular locations serviced by {{ agency.name }}</p>
          </div>
        </div>
        <div class="container">
          <div class="row mt-50">
            <div :class="cityColumns(index)" v-for="(city, index) in this.topCities" :key="city.value">
              <div class="card-image-top hover-up"><router-link :to="'/searchjobs?jobs[query]=' + city.value + '&jobs[refinementList][agencyName][0]=' + agency.name">
                  <div class="image cityBackgroundImage" :style="'background-image: url(https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fcities%2F' + cityToImageName(city.value) + '?alt=media), url(/assets/imgs/default_city.png);'"><span class="lbl-hot">Hot</span></div></router-link>
                <div class="informations"><router-link :to="'/searchjobs?jobs[query]=' + city.value + '&jobs[refinementList][agencyName][0]=' + agency.name">
                    <h5>{{city.value}}</h5></router-link>
                  <div class="row">
                    <div class="col-lg-6 col-6"><span class="text-14 color-text-paragraph-2">{{city.count}} Jobs</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      



            
        </main>

    </div>

</template>

<script>


import Typesense from 'typesense';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper core and required modules
import SwiperCore, {
  Autoplay
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay]);

// Import Swiper styles
import 'swiper/css';

import {getJobUrl} from "../helpers/urls";



import * as contentful from 'contentful';
const client = contentful.createClient({
    space: 'esusysdnccly',
    environment: 'master', // defaults to 'master' if not set
    accessToken: 'Sx-djpe1nCJKYieO0L0XKapqJ_Wtkth0i8Imrer5IhM',
    timeout: 1000
});


import JobTile from "./molecules/JobTile";


export default {

    components: {
        Swiper,
        SwiperSlide,
        JobTile,
    },

    created() {

        this.typesense = new Typesense.Client({
            'nodes': [{
                'host': 'g4ip5nk9yo0txzr3p-1.a1.typesense.net',
                'port': '443',
                'protocol': 'https'
            }],
            'apiKey': '7MwD0IITV2OcXRYlzMa9b6cREg9tJMOu',
            'connectionTimeoutSeconds': 2
        });
        this.typesense.collections("agencies").documents().search({
            'q': "",
            'query_by': 'slug',
            'per_page': 1,
            'filter_by': 'slug:=' + this.$route.params.agencyId,
        }).then((results) => {
            console.log("agency document", results);
            this.agency = results.hits[0].document;
            console.log(this.agency, this.agency.agencyId);
            return this.agency.agencyId;
        }).then((agencyId) => {
            console.log("AGENCY", agencyId);
            return this.typesense.collections('jobs').documents().search({
                'q': "",
                'query_by': 'agencyId',
                'facet_by': 'facilityLocation.disp,specialty.id,specialty.label',
                'per_page': 16,
                'sort_by': 'totalPay:desc',
                'filter_by': 'status:=Active && agencyId:=' + agencyId,
        })
        }).then((results) => {
            console.log("RESULTS", results, this.topCities, this.topSpecialties);
            this.topJobs = results.hits.map(item => item.document)
            this.topCities = results.facet_counts.find((item)=> item.field_name == "facilityLocation.disp").counts.slice(0,6) || [];
            this.topSpecialties = results.facet_counts.find((item)=> item.field_name == "specialty.id").counts || [];
            this.topSpecialtiesLabels = results.facet_counts.find((item)=> item.field_name == "specialty.label").counts || [];
            console.log("Specialities", this.topSpecialties);
        });

        client.getEntries({
            content_type: 'blogEntry',
        }).then((entry) => {
            console.log("IN ENTRY");
            this.entries = entry.items.map((item) => item.fields);
        })

        
    },

    data() {
        return {
            agency: {
                name: "Loading",
            },
            searchQuery: "", typesense: null, topJobs: [], responsiveOptions: [
                {
                    breakpoint: '3000px',
                    numVisible: 4,
                    numScroll: 2
                },
                {
                    breakpoint: '1024px',
                    numVisible: 3,
                    numScroll: 2
                },
                {
                    breakpoint: '600px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '480px',
                    numVisible: 2,
                    numScroll: 2
                }
            ],
            topCities: [],
            topSpecialties: [],
            entries: false,
        }
    },

    methods: {
        doSearch() {
            if (this.searchQuery != "")
                this.$router.push({ path: '/searchjobs', 'query': { 'jobs[query]': this.searchQuery , 'agency': this.agency.name} })
        },
        cityToImageName(city) {
            return city.replaceAll(", ", "_").replaceAll(" ", "_").toLowerCase() + ".jpg";
        },
        cityColumns(index) {
            if (index == 0 || index == 5) {
                return "col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12";
            }
            if (index == 1 || index == 4) {
                return "col-xl-4 col-lg-4 col-md-7 col-sm-12 col-12";
            }
            if (index == 2 || index == 3) {
                return "col-xl-5 col-lg-5 col-md-7 col-sm-12 col-12";
            }
        },
        getJob(a,b,c) {
            return getJobUrl(a,b,c);
        },
        getSpecialtyImage(specialty) {

            return "https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fspecialties%2F" + specialty + ".jpg?alt=media";


        }
    }

}
</script>
<style >

.logoImage {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
}
.blink::after {
    content: '|';
    animation: blink 1s infinite step-start;
}

.p-carousel-prev,
.p-carousel-next {
    display: none !important;
}

.p-carousel-item {
    padding-left: 10px;
    padding-right: 10px;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

/* center the blockquote in the page */
.blockquote-wrapper {
    display: flex;
}

/* Blockquote main style */
.blockquote {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    color: #243f99;
    padding: 30px 0;
    width: 100%;
    max-width: 500px;
    z-index: 1;
    margin: auto;
    align-self: center;
    border-top: solid 1px #243f99;
    border-bottom: solid 1px #243f99;
    margin-top: 2rem;
}

/* Blockquote header */
.blockquote h2 {
    position: relative;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1.25rem;
    line-height: 2.5rem;
}

/* Blockquote right double quotes */
.blockquote:after {
    position: absolute;
    content: "”";
    font-size: 10rem;
    line-height: 0;
    bottom: -43px;
    right: 30px;
    color: #243f99;
}

.cityBackgroundImage {
    background-size: cover;
    background-position: center center;
}

.banner-hero.hero-2 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fassets%2Fhome_hero.jpg?alt=media');
    background-position: center top;
}

.list-tags-banner, .tagLine {
    text-shadow: #041d52 0px 0px 20px, #041d52 0px 0px 20px, #041d52 0px 0px 20px;
}
</style>