<template>
    <div class="card-grid-3 hover-up">
      <div class="text-center card-grid-3-image"><router-link :to="'/blog/' + entry.slug">
          <figure><img alt="jobBox" :src="entry.mainImage.fields.file.url"></figure></router-link></div>
      <div class="card-block-info">
        <h5><router-link :to="'/blog/' + entry.slug">{{entry.title}}</router-link></h5>
        <div class="mt-10 color-text-paragraph font-sm blogContent" v-html="getBody()"></div>
        <p><router-link :to="'/blog/' + entry.slug" class="btn btn-light btn-sm mt-2">Read More</router-link></p>
        <div class="card-2-bottom mt-20">
          <div class="row">
            <div class="col-lg-6 col-6">
              <div class="d-flex">
                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">{{entry.author}}</span><br><span class="font-xs color-text-paragraph-2">{{formatTimestamp(entry.publishedOn)}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>

import moment from 'moment';

export default {

    components: {

    },

    props: ['entry'],

    data() {
        return {
            blogPost: { found: false }
        }
    },

    methods: {

        formatTimestamp(ts) {
            return moment(new Date(ts)).format("MM/DD/YYYY");
        },

        getBody() {
            var body = this.entry.summary;
            return body;
        }
    }

}

</script>
<style scoped>
.blogContent  { max-height: 150px; overflow:hidden}



</style>