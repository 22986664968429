
<template>


    <div :class="floating + ' chatBox bg-white rounded'">
        <div class="loading" v-if="!loaded">Loading...</div>
        <template v-else>
            <form @submit="sendMessage" v-if="!topDown">
                <div class="input-group mb-2">

                    <input type="text" class="form-control rounded-top-left-0" v-model="message" placeholder="send a new message ..."
                        aria-label="Message..." aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button class="btn btn-secondary" @click.prevent="sendMessage" type="submit"
                            style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                v-if="sendingChat"></span>
                            Send</button>

                    </div>

                </div>
            </form>
            <div v-if="messages.length == 0" style="min-height: 100px">
                No Messages yet
            </div>
            <div v-else :class="messagesClass + ' messages '" ref="messages">
                <transition-group name="list" tag="div">
                    <div :class="message.fromType + ' message p-2'" v-for="message in messages" :key="message.docId">
                        <table border="0" width="80%">
                            <tr>
                                <td width="40" valign="top">
                                    <img :src="message.photo" class="rounded-circle profile-photo" />
                                </td>
                                <td>
                                    <div class="messageWrapper shadow-sm">
                                        {{ message.message }}</div>
                                    <div class="byLine">{{ message.fromName }} - {{ formatTimestamp(message.timestamp)
                                    }}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </transition-group>
            </div>
            <form @submit="sendMessage" v-if="topDown">
                <div class="input-group">

                    <input type="text" class="form-control rounded-top-left-0" v-model="message" placeholder="send a new message ..."
                        aria-label="Message..." aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button class="btn btn-secondary" @click.prevent="sendMessage" type="submit"
                            style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                v-if="sendingChat"></span>
                            Send</button>

                    </div>

                </div>
            </form>
        </template>




    </div>


</template>











<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



export default {
    components: {},

    unmounted() {
        this.snapshot();
    },

    mounted() {

        if (this.user.loggedIn && this.user.role) {
            this.loadChat();
        }



        //.catch((e) => { }).finally(() => this.loaded = true);
    },

    data() {
        return {
            loaded: false,
            messages: [],
            message: "",
            sendingChat: false
        }
    },
    props: ['jobId', 'applicantId', 'floatingChat', 'topDown'],

    methods: {
        updateMessageView(source) {

            var data = {};
            if (this.user.data.uid == this.applicantId) {
                data['notification.nurseView'] = new Date().getTime();
                data['notification.nurseNewMessage'] = false;
                data['notification.nurseAttention'] = firebase.firestore.FieldValue.delete();
            } else {
                data['notification.recruiterView'] = new Date().getTime();
                data['notification.recruiterNewMessage'] = false;
                data['notification.recruiterAttention'] = firebase.firestore.FieldValue.delete();
            }

            firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").doc(this.applicantId).update(data).then(() => console.warn("view udpated", data, source)).catch((e) => console.error("View", e));

        },
        loadChat() {
            var snapshot = null;
            if (this.user.role.type == "agency_admin") {
                snapshot = firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").doc(this.applicantId).collection("messages").where("agencyId", "==", this.user.role.agencyId);
            } else if (this.user.role.type == "recruiter") {
                snapshot = firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").doc(this.applicantId).collection("messages").where("agencyId", "==", this.user.role.agencyId);
            } else {
                snapshot = firebase.firestore().collection("jobs").doc(this.jobId).collection("applications").doc(this.applicantId).collection("messages");
            }

            this.snapshot = snapshot.orderBy("timestamp", "desc").onSnapshot((snapshot) => {

                console.log("Got SNAPSHOT", snapshot);
                if (!this.loaded) {
                    //first time load
                    this.messages = snapshot.docs.map(doc => {
                        var data = doc.data();
                        data.docId = doc.id;
                        return this.prepareMessage(data);
                    });

                    if (this.topDown)
                        this.messages = this.messages.reverse();
                    this.loaded = true;
                    this.updateMessageView("new");
                    console.log("First Snapshot", snapshot);
                } else {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            var data = change.doc.data();
                            data.docId = change.doc.id;
                            if (!this.topDown)
                                this.messages.unshift(this.prepareMessage(data))
                            else
                                this.messages.push(this.prepareMessage(data));
                            console.log("snapshot update", snapshot);

                            if (data.from != this.user.data.uid)
                                this.updateMessageView("update");

                        }
                    });
                }

                var self = this;
                if (this.floatingChat) {
                    setTimeout(function () { self.$refs.messages.lastChild.lastChild.scrollIntoView() }, 500);
                } else {

                    setTimeout(function () { self.$refs.messages.scrollTop = self.$refs.messages.scrollHeight + 1000; }, 500);
                }
            }, (e) => {
                console.error("ERROR", e)
            });
        },
        formatTimestamp(ts) {
            return moment(new Date(ts)).format("MM/DD/YYYY h:mm a");
        },

        prepareMessage(message) {
            if (message.from == message.applicantId) {
                message.fromType = "nurse";
            } else {
                message.fromType = "recruiter";
            }
            return message;

        },
        sendMessage() {
            if (this.message == "")
                return;
            var message = {
                from: this.user.data.uid,
                message: this.message,
                timestamp: Date.now(),
                fromName: this.user.profile.name || this.user.data.displayName,
                photo: this.user.profile.profile_image || this.user.data.photo || "",
                applicantId: this.applicantId,
                jobId: this.jobId
            }

            // if (this.message != "") {
            this.sendingChat = true;
            const apply = firebase.app().functions('us-central1').httpsCallable("apply-newMessage")
            console.log("MESSAGE", message);
            apply(message).then((data) => {

                console.log("apply response", data);
            }).then(() => {
                this.message = "";
                this.sendingChat = false;
                this.$gtag.event('chatMessage')
            }).catch(() => this.sendingChat = false);
            //}
        }
    },

    computed: {
        ...mapGetters({
            user: "user",
            agency: "agency"
        }),

        floating() {
            return this.floatingChat ? "floating" : "";
        },

        messagesClass() {
            if (this.topDown)
                return "messages-height";
            else 
                return "";
        }
    }
}
</script>

<style>
.list-enter-active,
.list-leave-active {
    transition: all 1s;
}

.list-enter-from,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
    {
    opacity: 0;
    transform: translateY(30px);
}

.profile-photo {
    max-width: 30px;
    max-height: 30px;
    object-fit: cover;
    margin-top: .2rem;
}

.messages-height {
    max-height: 400px;
    overflow: auto;
}

.messageWrapper {
    padding: .2rem .5rem .2rem .5rem;
    background: #efefef;
    border-radius: 5px;
    display: inline-block;
}

.recruiter .messageWrapper {
    background: #333333;
    color: white;
}

.recruiter {
    justify-content: flex-end !important;
    display: flex;
}

.recruiter table {
    direction: rtl;
}

.byLine {
    padding: .2rem .5rem .2rem .5rem;
    font-size: .7rem;
}

.chatBox.floating .input-group {
    position: fixed;
    bottom: 0;
    left: 0;
}

.chatBox.floating .messages {
    padding-bottom: 50px;
}
</style>