<template>

    <div class="job-tile">



        <div class="card-grid-2 grid-bd-16 bg-white ">
            <div class="card-block-info pt-4">

                <h5><router-link :to="getJob(job.semantic, job['facilityLocation.disp'], job.title)">{{ job.title
                }}</router-link></h5>
                <router-link :to="getJob(job.semantic, job['facilityLocation.disp'], job.title)">
                <div class="mt-2 text-accent2 font-sm"><span class="card-location mr-15">{{
                        job['facilityLocation.city']
                }},
                        {{ job['facilityLocation.state'] }}</span> <span class="card-time">{{ job.hoursPerShift }} x {{
                                job.shiftsPerWeek
                        }}
                        ({{ job.shiftType }})</span></div>
                <div class="badge border border-warning text-black">Posted {{ timeFromNow(job.dateAdded) }}</div>
                
                <button class="btn btn-sm btn-apply hover-up btn-outline-cta text-black d-block font-montserrat mt-3 shadow-sm" style="font-weight: 500">
                  <i class="bi bi-check2-circle"></i>
                  I'm Interested</button>
                <hr />
                <div class="card-2-bottom mt-20">
                    <div class="row">
                        <div class="col-lg-7 col-md-7">
                            <div class="d-flex"><img class="logo"
                                    :src="'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/agencies%2F' + job.agencyId + '%2Flogo.png?alt=media'"
                                    alt="jobBox">
                                <div class="info-right-img">
                                    <h6 class="color-brand-1 lh-14 ms-1">{{
                                            job.agencyName
                                    }}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 text-end"><span class="card-text-price">{{ job.totalPay.toLocaleString("en-US", {style:"currency", currency:"USD", maximumFractionDigits:0})
                        }}</span><span class="text-muted">/Week</span>
                        </div>
                    </div>
                </div>
            </router-link>
            </div>
        </div>

    </div>


</template>


<script>
import moment from 'moment';
import { getJobUrl } from "../../helpers/urls";

export default {
    props: ['job'],

    methods: {
        formatTimestamp(ts) {
            return moment(new Date(ts)).format("MM/DD/YYYY h:mm a");
        },
        getJob(a, b, c) {
            return getJobUrl(a, b, c);
        },
        timeFromNow(time) {
            return moment.unix(time / 1000).fromNow()
        },

    }
}
</script>
