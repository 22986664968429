<template>

<div>

    <div class="card shadow bg-danger p-4 m-4 text-white">
    <h2 class="text-white">Sorry, something went wrong</h2>
    <p class="text-white">
        Looks like you don't have permissions to access this page.
    </p>
</div>

</div>
    
    
    </template>
    
    
    <script>

    export default {

    };
    </script>