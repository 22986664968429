<template>
  <img
    :src="
      'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/users%2F' +
      id +
      '%2Fprofile.jpg?alt=media'
    "
    alt="mdo"
    class="sm-profile-photo rounded-circle"
    referrerpolicy="no-referrer"
    @error="showBackup"
    v-if="!backup"
  />
  <div
    style="width: 32px; height: 32px; display: inline-block"
    class="rounded-circle bg-info"
    v-else
  >
    <div
      style="width: 100%; height: 100%; font-size: 20px"
      class="d-flex align-items-center justify-content-center"
    >
    <i class="bi bi-person-circle"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      backup: false,
    };
  },

  methods: {
    showBackup() {
      this.backup = true;
    },
  },

};
</script>
