<template>
  

  
    <div :class="containerClass">
      <slot /> 
    </div>
    
  </template>
  
  <script>
  
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';

  import { mapGetters } from "vuex";

  export default {
  
    data() {
      return {
        backLink: "dashboard",
        toggleButton: true
      };
    },
  
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user"
      })
    },
  
    components: {

    },

    props: ['containerClass'],
  
    watch: {
      $route(to,from) {
        console.log("ROUTER", to, from);
  
        if (to.meta.back) {
          this.backLink = to.meta.back;
          this.toggleButton = false;
        } else {
          this.toggleButton = true;
        }
      }
    },
  
  
  
    methods: {
      signOut() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              name: "HomePage"
            });
          });
      }
    }
  };
  
  </script>
  
  <style>
  
  </style>
  
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  