<template>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top shadow-sm bg-dark topNav">
        <div class="container-lg">

            <div style="width: 58px" class="d-lg-none">
                <button v-show="toggleButton" class="navbar-toggler ms-1 border-0" type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <router-link v-show="!toggleButton" :to="backLink"
                    class="text-white navbar-toggler ms-1 border-0 nav-link px-2">
                    <span class="bi bi-chevron-left"></span>
                </router-link>
            </div>



            <div class="order-1">
                <router-link to="/" class="navbar-brand">
                    <span style="font-family: 'Ms Madi'; font-weight: bold; font-size: 2rem;">Refined</span> <span
                        style="font-family: Montserrat;">Health Pros</span>
                </router-link>
            </div>
            <!--<img src="@/assets/refined_white2.png" width="125"/>-->
            <div class="offcanvas offcanvas-start d-lg-none" style="width:300px" tabindex="-1" id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel">
                <div class="offcanvas-header">
                    <a class="navbar-brand" href="/"><span
                            style="font-family: 'Ms Madi'; font-weight: bold; font-size: 2rem;">Refined</span> <span
                            style="font-family: Montserrat;">Health Pros</span></a>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                        ref="closeButton"></button>
                </div>
                <div class="offcanvas-body">
                    <ProfileImage v-if="isLoggedIn" />
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li>
                            <router-link to="/dashboard" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">Dashboard</router-link>
                        </li>
                        <li>
                            <router-link to="/searchjobs" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">
                                Search Jobs</router-link>
                        </li>
                        <template v-if="isLoggedIn">
                            <li>
                                <router-link to="/profile" class="nav-link px-2 link-primary"
                                    v-if="user.role && user.role.type == 'nurse'"
                                    @click="this.$refs.closeButton.click()">
                                    Profile
                                </router-link>
                            </li>

                            <li>
                                <router-link to="/profile/applications" class="nav-link px-2 link-primary"
                                    v-if="user.role && user.role.type == 'nurse'"
                                    @click="this.$refs.closeButton.click()">
                                    Applications
                                </router-link>
                            </li>
                        </template>

                    </ul>
                    <hr />
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li>
                            <router-link to="/pages/aboutus" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">About Us</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/employers" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">Browse Employers</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/contactus" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">Contact</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/partnerships" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">Partnerships</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/faqs" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">FAQs</router-link>
                        </li>
                        <li>
                            <router-link to="/blog" class="nav-link px-2 link-primary"
                                @click="this.$refs.closeButton.click()">Latest News</router-link>
                        </li>
                    </ul>
                    <form class="d-flex mt-3" role="search">
                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                        <button class="btn btn-outline-success" type="submit">Search</button>
                    </form>
                </div>
            </div>

            <div class="dropdown order-lg-3 order-2 me-2" style="text-align: right" v-if="isLoggedIn">
                <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle text-white"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <ProfileImageSmall :id="user.data.uid"></ProfileImageSmall>
                </a>
                <ul class="dropdown-menu text-small dropdown-menu-end">
                    <li><a class="dropdown-item" @click.prevent="signOut">Sign out</a></li>
                </ul>
            </div>
            <div class="dropdown order-lg-3 order-2 me-2" style=" text-align: right" v-if="!isLoggedIn">

                <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#loginOffcanvas" aria-controls="loginOffcanvas" id="LoginButton">Login</button>
                <router-link to="/register" class="btn btn-info ms-2 d-none d-lg-inline-block">Join <span
                        style="font-family: 'Ms Madi'; font-weight: bold; letter-spacing:.1rem">Refined</span></router-link> 
            </div>


            <div class=" order-2 d-none d-lg-block flex-grow-1" id="navbarTogglerDemo01" style="text-align:center">

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li>
                        <router-link to="/searchjobs" class="nav-link px-2 ">Search Jobs</router-link>
                    </li>
                    <li v-if="isLoggedIn">
                        <router-link to="/dashboard" class="nav-link px-2">Dashboard</router-link>
                    </li>
                    <template v-if="isLoggedIn">
                        <li>
                            <router-link to="/profile" class="nav-link px-2 "
                                v-if="user.role && user.role.type == 'nurse'">Profile
                            </router-link>
                        </li>


                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Resources
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <router-link to="/pages/aboutus" class="dropdown-item">About Us</router-link>
                                </li>
                                <li>
                                    <router-link to="/pages/employers" class="dropdown-item">Browse Employers</router-link>
                                </li>
                                <li>
                                    <router-link to="/pages/partnerships" class="dropdown-item">For
                                        Agencies</router-link>
                                </li>
                                <li>
                                    <router-link to="/blog" class="dropdown-item">Latest News</router-link>
                                </li>
                                <li>
                                    <router-link to="/pages/faqs" class="dropdown-item">FAQs</router-link>
                                </li>
                                <li>
                                    <router-link to="/pages/contactus" class="dropdown-item">Contact</router-link>
                                </li>



                            </ul>
                        </li>
                    </template>
                    <template v-else>
                        <li>
                            <router-link to="/pages/aboutus" class="nav-link px-2">About Us</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/employers" class="nav-link px-2">Employers</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/partnerships" class="nav-link px-2">For Agencies</router-link>
                        </li>
                        <li>
                            <router-link to="/blog" class="nav-link px-2">Latest News</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/faqs" class="nav-link px-2">FAQs</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/contactus" class="nav-link px-2">Contact</router-link>
                        </li>


                    </template>
                </ul>

            </div>
        </div>






    </nav>
</template>
  
<script>

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import ProfileImageSmall from '../components/molecules/ProfileImageSmall.vue';
import ProfileImage from '../components/molecules/ProfileImage.vue';
import { mapGetters } from "vuex";


export default {

    data() {
        return {
            backLink: "dashboard",
            toggleButton: true
        };
    },


    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        }),
        isLoggedIn() {
            return this.user.loggedIn;
        }
    },

    components: {
        ProfileImageSmall, ProfileImage
    },

    watch: {
        $route(to) {


            if (to.meta.back) {
                this.backLink = to.meta.back;
                this.toggleButton = false;
            } else {
                this.toggleButton = true;
            }
        },

        'user.loggedIn': {
            handler: function () {
                console.log("LOGGEDIN", this.user.loggedIn);
            }

        }

    },



    methods: {
        signOut() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$router.replace({
                        name: "HomePage"
                    });
                });
        }
    }
};

</script>