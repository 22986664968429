<template>
  <component :is="layout" :containerClass="container">
    <router-view>

    </router-view>
  </component>
</template>


<script>

/*
<transition name="route" mode="out-in">
        </transition>
        */
export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout || "DefaultLayout";
    },
    container() {
      return this.$route.meta.containerClass || "container-lg";
    }
  }


};
</script>
<style>
  /* Router */
  .route-enter-from {
    opacity: 0;
    transform: translateX(100px);
  }
  
  .route-enter-active {
    transition: all 0.3s ease-out;
  }
  
  .route-leave-to {
    opacity: 0;
    transform: translateX(-100px);
  }
  
  .route-leave-active {
    transition: all 0.3s ease-out;
  }
  </style>
