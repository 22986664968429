<template>
<div>
<div class="row bg-white"  v-if="item.status=='Closed'" ><div class="col p-2 border border-2 border-danger" style="background: rgba(255,0,0,.2)">This job is closed.  <router-link to="/searchjobs" >Search for 1000s of other jobs</router-link> to find the right one for you.</div></div>

    <div class="row shadow-sm bg-accent3 ais-Hits pt-2 pb-2 ">
        <div class="col align-items-center d-flex" style="flex: 0; width: 100px" v-if="showBack">
            <button class="btn btn-lg"><i class="bi bi-arrow-left-circle" style="font-size:2rem;" @click.prevent="goBack"></i></button>
        </div>
        <div class="col align-items-center d-none d-md-flex" style="flex: 0; width: 100px">
            <div style="width: 120px; text-align: center">
                <img v-if="item" width="80" :src="
                    'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/agencies%2F' +
                    item.agencyId +
                    '%2Flogo.png?alt=media'
                " alt="Logo" />
            </div>
        </div>
        <div class="col ps-3 position-relative">
            <div class="job-title" itemprop="title">
                {{item.title}}
            </div>
            <div class="job-location" itemprop="hiringOrganization" itemscope itemtype="https://schema.org/Organization">
                <span itemprop="name">{{item.facilityName}}</span>
            </div>
            <div class="job-location" itemprop="jobLocation" itemscope itemtype="https://schema.org/Place"><span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
                <span itemprop="addressLocality">{{ item['facilityLocation.city'] }}</span>,
                <span itemprop="addressRegion">{{ item['facilityLocation.state'] }}</span></span>
            </div>
            

            <span class="job-pay d-lg-none"  itemprop="baseSalary">$ {{ item.totalPay }}</span>

            <button class="btn mt-2 mb-2 d-md-none d-block btn-apply btn-apply-big hover-up btn-primary btn-sm" v-if="!applicationExists" @click.prevent="$emit('apply', item.jobId)" :disabled="!!appInProgress"
                >
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" v-if="appInProgress"></span> 
                <i class="bi bi-check2-circle" v-if="!appInProgress"></i>
                Apply Now</button>
            <div v-else class="d-block d-md-none">&#128077; Applied</div>

            <img v-if="item" width="40" class="position-absolute bottom-0 end-0 me-3 mb-0 d-md-none d-block" :src="
                    'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/agencies%2F' +
                    item.agencyId +
                    '%2Flogo.png?alt=media'
                " alt="Logo" />

        </div>

        <div class="col-md-4"
            v-if="user && user.role && (user.role.type == 'agency_admin' || user.role.type == 'recruiter')">
            <div class="job-desc-highlight" v-if="item.specialty">{{ item.specialty.label }}</div>
            <div>${{ item.taxableSalary }} + ${{ item.stipend }}</div>
            <div class="job-pay">{{ item.totalPay.toLocaleString("en-US", {style:"currency", currency:"USD", maximumFractionDigits:0}) }}</div>
            <div>JOB ID: <span class="job-desc-highlight">{{ item.jobId }}</span></div>
            <div>Start Date: <span class="job-desc-highlight">{{ formatTimestamp(item.startDate) }}</span></div>

        </div>
        <template v-else>
        <div class="col-md-2 job-pay align-items-center d-flex d-none d-md-flex" >

            {{ item.totalPay.toLocaleString("en-US", {style:"currency", currency:"USD", maximumFractionDigits:0}) }}

        </div>
        <div class="col-md-2 job-pay align-items-center d-flex d-none d-md-flex text-lg-end" >

            <button class="btn  btn-apply btn-apply-big hover-up btn-primary btn-lg" v-if="!applicationExists" @click.prevent="$emit('apply', item.jobId)" :disabled="!!appInProgress"
                >
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" v-if="appInProgress"></span> 
                <i class="bi bi-check2-circle" v-if="!appInProgress"></i>
                Apply Now</button>
            <div v-else>&#128077; Applied</div>

        </div>
    </template>
    </div>
    
</div>

</template>


<script>

import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    props: ['item', 'applicationExists', 'showBack', 'appInProgress'],

    methods: {
        formatTimestamp(ts) {
            return moment(new Date(ts)).format("MMM YYYY");
        },

        goBack() {
            this.$router.replace(this.showBack);
        }
    },

    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        })
    },
}
</script>
