<template>
  
    <RefinedNav />
    
  
    <div :class="containerClass + ' DefaultLayoutBody'">
      <EmailVerification />
      
      <slot /> 
    </div>
    <div class="container-fluid border-top mt-2 bg-white">
    <FooterView />
  </div>
    <div class="offcanvas offcanvas-end" tabindex="-1"  id="loginOffcanvas" aria-labelledby="offcanvasLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasLabel">Login</h5>
      <button type="button" id="closeLoginOffCanvas" class="btn-close text-reset" data-bs-dismiss="offcanvas" data-bs-target="#loginOffcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <LoginBox/>
    </div>
  </div>
  </template>
  
  <script>
  
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import 'firebase/compat/firestore';


  import { mapGetters } from "vuex";
  import RefinedNav from './RefinedNav.vue';
  import FooterView from './FooterView.vue';
  import EmailVerification from '../components/molecules/EmailVerification.vue';
  export default {
  
    data() {
      return {
        backLink: "dashboard",
        toggleButton: true
      };
    },

    props: ['containerClass'],
  
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user"
      })
    },
  
    components: {
          RefinedNav, FooterView,EmailVerification
    },
  
    watch: {
      $route(to) {

  
        if (to.meta.back) {
          this.backLink = to.meta.back;
          this.toggleButton = false;
        } else {
          this.toggleButton = true;
        }
      }
    },
  
  
  
    methods: {
      signOut() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace({
              name: "HomePage"
            });
          });
      }
    }
  };
  
  </script>
  
  <style>
  nav.topNav {
    height: 4rem;
  }
  
  /* Router */
  .route-enter-from {
    opacity: 0;
    transform: translateX(100px);
  }
  
  .route-enter-active {
    transition: all 0.3s ease-out;
  }
  
  .route-leave-to {
    opacity: 0;
    transform: translateX(-100px);
  }
  
  .route-leave-active {
    transition: all 0.3s ease-out;
  }
  
  .navbar-toggler:focus {
    outline: none !important;
    box-shadow: none !important;
    color: white !important;
  }
  
  </style>
  
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>

  <style>


.DefaultLayoutBody {
    margin-top: 4rem;
}
</style>
  