<template>
    <div class="row bg-white verifyEmailPrompt" v-if="visible">
        <div class="col p-2 border border-2 border-danger inner text-center" style="">

            <div v-if="!verificationEmailSent">You are logged in, but your e-mail
                is <b>not</b> verified. <a href="javascript:void(0);" @click.prevent='startVerification'>Click here to
                    verify your e-mail</a> now. It just takes a quick second. <button class="btn btn-secondary btn-sm"
                    @click.prevent="startVerification">Verify Now</button></div>

            <div v-if="verificationEmailSent">We've sent an e-mail to your address on file.  Please click the link contained in the e-mail to complete this verification.</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {

    data() {
        return {
            verificationEmailSent: false
        }
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        }),
        visible() {
            return this.user.loggedIn && !this.user.isVerified;
        }
    },

    methods: {
        startVerification() {
            this.user.data._user.sendEmailVerification().then(() => {
                this.verificationEmailSent = true;
            })
        }
    },

}
</script>

<style>

</style>