<template>
    <div class="text-center position-relative" v-if="user" @mouseover="hover = true" @mouseleave="hover=false">

        <button class="btn btn-outline-primary position-absolute top-50 start-50 translate-middle" v-if="hover"
            @click="chooseProfileImage">Edit</button>
        <input type="file" ref="input1" style="display: none" @change="previewImage" accept="image/jpg">

        <img :src="user.photo" alt="Profile Image" class="lg-profile-photo rounded-circle"
            referrerpolicy="no-referrer" v-if="user.photo"/>
        <div style="width: 100px; height: 100px; display: inline-block; " class="rounded-circle bg-info" v-else>
        
            <div style="width: 100%; height: 100%; font-size: 40px" class="d-flex align-items-center justify-content-center"><i class="bi bi-person-circle"></i></div>
        
        </div>
        <br />
        <template v-if="!hideName">
            {{displayNameComputed}}
        </template>

      

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';




export default {

    data() {
        return {
            hover: false,
            img1: "",
            imageData: "",
        }
    },

    props: ['hideName'],


    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        }),

        displayNameComputed() {
            if (this.user.profile && this.user.profile.basicInfo && this.user.profile.basicInfo.firstName && this.user.profile.basicInfo.lastName) {
                return this.user.profile.basicInfo.firstName + " " + this.user.profile.basicInfo.lastName;
            } else if (this.user.profile && this.user.profile.name) {
                return this.user.profile.name;
            } else {
                return this.user.data.displayName;
            }
        }
    },


    methods: {

        chooseProfileImage() {
            this.$refs.input1.click()
        },
        previewImage(event) {
            this.uploadValue = 0;
            this.img1 = null;
            this.imageData = event.target.files[0];
            this.saveImage()
        },

        saveImage() {
            this.img1 = null;
            const storageRef = firebase.storage().ref("users/" + this.user.data.uid + "/profile.jpg").put(this.imageData);
            storageRef.on(`state_changed`, snapshot => {
                this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, error => { console.log(error.message) },
                () => {
                    this.uploadValue = 100;
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.img1 = url;
                        console.log(this.user.uid, url);
                        firebase.firestore().collection("profiles").doc(this.user.data.uid).update({"profile_image": url}).catch((e) => {console.log(e);})
                    });
                }
            );
        },
    }


}


</script>
