<template>
    <div class="row">
        <main class="main bg-white ps-0 pe-0">
            <section class="section-box">
                <div class="banner-hero hero-2">
                    <div class="banner-inner">
                        <div class="block-banner">
                            <h1 class="text-42 color-white wow animate__animated animate__fadeInUp"><span
                                    style="font-family: 'Ms Madi'">Refining</span><br v-if="$isMobile()" /> <span 
                                    class="color-green" style="font-family: Montserrat;">

                                    <v-typical class="blink"
                                        :steps="['Healthcare', 3000, 'The Market', 3000, 'The Job Search', 3000]"
                                        :loop="Infinity" :wrapper="'span'"></v-typical>

                                </span></h1>
                            <div class="font-lg font-regular color-white mt-20 wow animate__animated animate__fadeInUp tagLine"
                                data-wow-delay=".1s">Experience the care you deserve<br class="d-none d-lg-block" /> with the professionals you can trust!</div>
                            <div class="form-find mt-40 wow animate__animated animate__fadeIn" data-wow-delay=".2s">
                                <form>
                                    <input class="form-input input-keysearch mr-10" type="text"
                                        placeholder="Your keyword... " v-model="searchQuery">
                                    <button class="btn btn-primary btn-find font-sm"
                                        @click.prevent="doSearch">Search</button>
                                </form>
                            </div>
                            <div class="list-tags-banner mt-20 wow animate__animated animate__fadeInUp"
                                data-wow-delay=".3s"><strong>Popular Searches:</strong>
                                <router-link to="/searchjobs?jobs[query]=telemetry">Telemetry</router-link>,
                                <router-link to="/searchjobs?jobs[query]=ER">ER</router-link>,
                                <router-link to="/searchjobs?jobs[query]=ICU">ICU</router-link>,
                                <router-link to="/searchjobs?jobs[query]=Allied">Allied</router-link>,
                                <router-link to="/searchjobs?jobs[query]=CNA">CNA</router-link>,
                            </div>
                        </div>
                        <div class="mt-60" v-if="false">
                            <div class="row">
                                <div class="col-lg-3 col-sm-3 col-6 text-center mb-20">
                                    <div class="d-inline-block text-start">
                                        <h4 class="color-white"> <span class="count">265</span><span> K+</span></h4>
                                        <p class="font-sm color-text-mutted">Daily Jobs Posted</p>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-3 col-6 text-center mb-20">
                                    <div class="d-inline-block text-start">
                                        <h4 class="color-white"><span class="count">17</span><span> K+</span></h4>
                                        <p class="font-sm color-text-mutted">Recruiters</p>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-3 col-6 text-center mb-20">
                                    <div class="d-inline-block text-start">
                                        <h4 class="color-white"> <span class="count">15</span><span> K+</span></h4>
                                        <p class="font-sm color-text-mutted">Freelancers</p>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-3 col-6 text-center mb-20">
                                    <div class="d-inline-block text-start">
                                        <h4 class="color-white"> <span class="count">28</span><span> K+</span></h4>
                                        <p class="font-sm color-text-mutted">Blog Tips</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section class="section-box mt-30  ps-2 pe-2">
                <div class="container">




                    <div class="text-center mb-3">
                        <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated"
                            style="visibility: visible; animation-name: fadeInUp;">Premier Jobs</h2>
                    </div>
                    <div class="row">


                        <swiper :slides-per-view="4" :space-between="25"     :loop="true"
:autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }" :breakpoints="{
        '0': {
        slidesPerView: 2,
        spaceBetween: 25,
      },'480': {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      '1024': {
        slidesPerView: 4,
        spaceBetween: 25,
      },
       
    }">

<swiper-slide v-for="job in topJobs" :key="job"> 
    <JobTile :job="job" />
                                </swiper-slide>
                            </swiper>


                    </div>
                </div>
            </section>

            <section class="section-box mt-50 mb-30 bg-border-3 pt-100 pb-100 ps-3 pe-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pe-2"><img class="bdrd-10 w-100"
                                src="https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fassets%2FPicture2.jpg?alt=media" alt="jobBox"></div>
                        <div class="col-lg-6 ps-2 d-flex align-items-center">
                            <div class="pl-30 ps-3 pe-3">
                                <h2 class="color-brand-1 mt-0 mb-15">Building Your Travel Nursing Profile should be Fast and Easy.  Let the Pros Help.</h2>
                                <p class="font-lg color-text-paragraph-2">Our intuitive profile builder will create a submission ready travel profile in just 5 steps.</p>
                                    <p class="font-lg color-text-paragraph-2">It's your Healthcare career Lets “Refine” it together
                                </p>
                                <div class="mt-20">
                                    <router-link class="btn btn-primary btn-lg" to="/register">Create your Profile
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-box mt-70 mb-40">
                <div class="container">
                    <div class="text-center">
                        <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated"
                            style="visibility: visible; animation-name: fadeInUp;">Creating the path to your next
                            assignment!</h2>
                        <p class="font-lg color-text-paragraph-2 wow animate__ animate__fadeInUp animated"
                            style="visibility: visible; animation-name: fadeInUp;">
                            At Refined Health Pros our goal is to make your hiring process
                            quick and easy. We got you covered!
                        </p>
                    </div>
                    <div class="mt-70">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="box-step step-1">
                                    <h1 class="number-element">1</h1>
                                    <h4 class="mb-20">Create your Profile</h4>
                                    <p class="font-lg color-text-paragraph-2">Creating your profile with our easy-to-use interface typically takes less than 15 minutes.</p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="box-step step-2">
                                    <h1 class="number-element">2</h1>
                                    <h4 class="mb-20">Explore your possibilities </h4>
                                    <p class="font-lg color-text-paragraph-2">Navigate through our marketplace to
                                        find the perfect assignment for you. Narrow your search to specialty, pay,
                                        location, hospitals, agencies, shift etc.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="box-step">
                                    <h1 class="number-element">3</h1>
                                    <h4 class="mb-20">Take control of the conversation</h4>
                                    <p class="font-lg color-text-paragraph-2">Communicate with employers and
                                        recruiters at your pace using our Realtime chat feature.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-50 text-center">
                        <router-link class="btn btn-primary btn-lg" to="/register">Get Started</router-link>
                    </div>
                </div>
            </section>

            <section class="section-box mt-50 bg-border-3 pt-100 pb-100 ps-3 pe-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pe-2"><img class="bdrd-10 w-100" src="/assets/imgs/Picture1.png"
                                alt="jobBox"></div>
                        <div class="col-lg-6 ps-2">
                            <div class="pl-30 ps-3 pe-3">
                                <div class="blockquote-wrapper">
                                    <div class="blockquote">
                                        <h2>
                                            Creating my profile with Refined Health Pros was a breeze! As a seasoned traveler, I was amazed at how effortlessly they organized my work history. They saved me so much time, it was like having a personal assistant!

                                        </h2>
                                        <h4>&mdash;Tanisha, MSN, Orlando, FL </h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-box mb-30 bg-brand-2 pt-5 pb-2">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5">
                            <div class="pt-2 ps-3">
                                <h2 class="color-white mb-20 ">Explore the Refined Marketplace</h2>
                                <p class="color-white mb-30 pe-3">Search for the best jobs in your specialty.  Tell us what your looking for and we'll get to work for you! </p>
                                <div class="mt-20"> <router-link class="btn btn-brand-1 btn-icon-more btn-lg hover-up btn-warning " style="padding-right: 50px;" to="/searchjobs">Explore</router-link></div>
                            </div>
                        </div>
                        <div class="col-xl-7 pt-4 pt-md-0">
                            <swiper :slides-per-view="4" :space-between="25"     :loop="true"
:autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :breakpoints="{
        '0': {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      '480': {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 25,
      },
      
    }">

                                <swiper-slide v-for="(item, index) in this.topSpecialties" :key="item.value">
                                    <div class="card-grid-5 card-category hover-up"
                                        :style="'background-image: url(' + getSpecialtyImage(item.value) + '); background-position: center bottom'">  
                                        <router-link :to="'/searchjobs?jobs[query]=' + this.topSpecialtiesLabels[index].value">
                                            <div class="box-cover-img"> 
                                                <div class="content-bottom">
                                                    <h6 class="color-white mb-5">{{topSpecialtiesLabels[index].value}}</h6>
                                                    <p class="color-white font-xs"><span>{{item.count}}</span><span> Jobs
                                                            Available</span></p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </swiper-slide>
                                
                            </swiper>
                        </div>

                    </div>

                </div>
            </section>

            <section class="section-box mt-50" v-if="this.topCities.length > 0">
        <div class="container">
          <div class="text-center">
            <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">Jobs by Location</h2>
            <p class="font-lg color-text-paragraph-2 wow animate__ animate__fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">Find your favorite jobs in your favorite city.  Explore a new adventure today!</p>
          </div>
        </div>
        <div class="container">
          <div class="row mt-50">
            <div :class="cityColumns(index)" v-for="(city, index) in this.topCities" :key="city.value">
              <div class="card-image-top hover-up"><router-link :to="'/searchjobs?jobs[query]=' + city.value">
                  <div class="image cityBackgroundImage" :style="'background-image: url(https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fcities%2F' + cityToImageName(city.value) + '?alt=media), url(/assets/imgs/default_city.png);'"><span class="lbl-hot">Hot</span></div></router-link>
                <div class="informations"><router-link :to="'/searchjobs?jobs[query]=' + city.value">
                    <h5>{{city.value}}</h5></router-link>
                  <div class="row">
                    <div class="col-lg-6 col-6"><span class="text-14 color-text-paragraph-2">{{city.count}} Jobs</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-box mt-50 mb-50">
        <div class="container">
          <div class="text-center">
            <h2 class="section-title mb-10 wow animate__ animate__fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">The Latest from Refined</h2>
            <p class="font-lg color-text-paragraph-2 wow animate__ animate__fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">Get the latest news, tips and industry updates</p>
          </div>
        </div>
        <div class="container">
          <div class="row mt-50">
            <div class="col-lg-4" v-for="item in entries" :key="item.title">
            <BlogEntry :entry="item" />

        </div>
            <div class="text-center mt-5"><router-link class="btn btn-secondary mt--30 hover-up" to="/blog">Load More &gt;</router-link></div>
          </div>
        </div>
      </section>




            
        </main>

    </div>

</template>

<script>


import VTypical from 'vue-typical';
import Typesense from 'typesense';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper core and required modules
import SwiperCore, {
  Autoplay
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay]);

// Import Swiper styles
import 'swiper/css';

import {getJobUrl} from "../helpers/urls";



import * as contentful from 'contentful';
const client = contentful.createClient({
    space: 'esusysdnccly',
    environment: 'master', // defaults to 'master' if not set
    accessToken: 'Sx-djpe1nCJKYieO0L0XKapqJ_Wtkth0i8Imrer5IhM',
    timeout: 1000
});

import BlogEntry from "./molecules/BlogEntry";
import JobTile from "./molecules/JobTile";


export default {

    components: {
        VTypical, Swiper,
        SwiperSlide,
        BlogEntry,
        JobTile,
    },

    created() {

        this.typesense = new Typesense.Client({
            'nodes': [{
                'host': 'g4ip5nk9yo0txzr3p-1.a1.typesense.net',
                'port': '443',
                'protocol': 'https'
            }],
            'apiKey': '7MwD0IITV2OcXRYlzMa9b6cREg9tJMOu',
            'connectionTimeoutSeconds': 2
        });
        this.typesense.collections('jobs').documents().search({
            'q': "",
            'query_by': 'title',
            'facet_by': 'facilityLocation.disp,specialty.id,specialty.label',
            'per_page': 16,
            'sort_by': 'totalPay:desc',
            'filter_by': 'status:=Active',
        }).then((results) => {
            console.log("RESULTS", results, this.topCities, this.topSpecialties);
            this.topJobs = results.hits.map(item => item.document)
            this.topCities = results.facet_counts.find((item)=> item.field_name == "facilityLocation.disp").counts.slice(0,6) || [];
            this.topSpecialties = results.facet_counts.find((item)=> item.field_name == "specialty.id").counts || [];
            this.topSpecialtiesLabels = results.facet_counts.find((item)=> item.field_name == "specialty.label").counts || [];
            console.log("Specialities", this.topSpecialties);
        });

        client.getEntries({
            content_type: 'blogEntry',
        }).then((entry) => {
            console.log("IN ENTRY");
            this.entries = entry.items.map((item) => item.fields);
        })

        
    },

    data() {
        return {
            searchQuery: "", typesense: null, topJobs: [], responsiveOptions: [
                {
                    breakpoint: '3000px',
                    numVisible: 4,
                    numScroll: 2
                },
                {
                    breakpoint: '1024px',
                    numVisible: 3,
                    numScroll: 2
                },
                {
                    breakpoint: '600px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '480px',
                    numVisible: 2,
                    numScroll: 2
                }
            ],
            topCities: [],
            topSpecialties: [],
            entries: false,
        }
    },

    methods: {
        doSearch() {
            if (this.searchQuery != "")
                this.$router.push({ path: '/searchjobs', 'query': { 'jobs[query]': this.searchQuery } })
        },
        cityToImageName(city) {
            return city.replaceAll(", ", "_").replaceAll(" ", "_").toLowerCase() + ".jpg";
        },
        cityColumns(index) {
            if (index == 0 || index == 5) {
                return "col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12";
            }
            if (index == 1 || index == 4) {
                return "col-xl-4 col-lg-4 col-md-7 col-sm-12 col-12";
            }
            if (index == 2 || index == 3) {
                return "col-xl-5 col-lg-5 col-md-7 col-sm-12 col-12";
            }
        },
        getJob(a,b,c) {
            return getJobUrl(a,b,c);
        },
        getSpecialtyImage(specialty) {

            return "https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fspecialties%2F" + specialty + ".jpg?alt=media";


        }
    }

}
</script>
<style >
.blink::after {
    content: '|';
    animation: blink 1s infinite step-start;
}

.p-carousel-prev,
.p-carousel-next {
    display: none !important;
}

.p-carousel-item {
    padding-left: 10px;
    padding-right: 10px;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

/* center the blockquote in the page */
.blockquote-wrapper {
    display: flex;
}

/* Blockquote main style */
.blockquote {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    color: #243f99;
    padding: 30px 0;
    width: 100%;
    max-width: 500px;
    z-index: 1;
    margin: auto;
    align-self: center;
    border-top: solid 1px #243f99;
    border-bottom: solid 1px #243f99;
    margin-top: 2rem;
}

/* Blockquote header */
.blockquote h2 {
    position: relative;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1.25rem;
    line-height: 2.5rem;
}

/* Blockquote right double quotes */
.blockquote:after {
    position: absolute;
    content: "”";
    font-size: 10rem;
    line-height: 0;
    bottom: -43px;
    right: 30px;
    color: #243f99;
}

.cityBackgroundImage {
    background-size: cover;
    background-position: center center;
}

.banner-hero.hero-2 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/public%2Fassets%2Fhome_hero.jpg?alt=media');
    background-position: center top;
}

.list-tags-banner, .tagLine {
    text-shadow: #041d52 0px 0px 20px, #041d52 0px 0px 20px, #041d52 0px 0px 20px;
}
</style>