<template>


    <div class="card col border-0 bg-accent3">
        <div class="card-body" v-if="!showForgotPassword">


            <div v-if="error.length > 0">{{ error }}</div>

            <div class="d-grid d-md-flex align-items-center justify-content-center gap-2">
                <button type="button" class="btn btn-outline-primary" @click="loginWithFacebook">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-facebook" viewBox="0 0 16 16">
                        <path
                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z">
                        </path>
                    </svg>
                    Login with facebook
                </button>
                <button type="button" class="btn btn-outline-primary" @click="loginWithGoogle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-google" viewBox="0 0 16 16">
                        <path
                            d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                    </svg>
                    Login with Google
                </button>
            </div>
            <hr />

            <form @submit.prevent="submit">
                <p>Or login with your <span
                        style="font-family: 'Ms Madi';font-size: 1.3rem; font-weight: bold">Refined</span> account email
                    / password.</p>
                <p v-if="form.errors.length">
                    <b>Please correct the following error(s):</b>
                <ul>
                    <li v-for="error in form.errors" v-bind:key="error">{{ error }}</li>
                </ul>
                </p>

                <div class="form-group mb-2">
                    <input type="email" class="form-control" aria-describedby="emailHelp"
                        placeholder="Enter email" required v-model="form.email">
                </div>
                <div class="form-group mb-2">
                    <input type="password" class="form-control"  placeholder="Password" required
                        v-model="form.password">
                </div>
                <div class="d-flex align-items-center justify-content-between gap-2">
                    <a href="javascript:void()" @click.prevent="showForgotPassword = true">Forgot Password?</a><button
                        type="submit" class="btn btn-primary">Login with <span
                            style="font-family: 'Ms Madi';font-size: 1.3rem;">Refined</span></button>
                </div>
            </form>

            <hr />

            <div class="d-grid gap-2 mt-3">
                <p>If you do not currently have a Refined Health Pro account, <router-link to="/register"
                        @click="closeLoginOffcanvas" class="">click here</router-link> to <router-link to="/register"
                        @click="closeLoginOffcanvas" class="">Register Now</router-link>. It will only take a few
                    minutes to create your profile.</p>



            </div>
        </div>
        <div class="card-body" v-else>

            <h6>Reset my password</h6>
            <p>Please enter your email address below. We will send you an e-mail with instructions on how to reset your
                password.</p>
            <form >
                <div class="form-group mb-2">
                    <input type="email" class="form-control" id="emailAddress" aria-describedby="emailHelp"
                        placeholder="Enter email" required v-model="forgotEmail">
                </div>

                <div>

                    <ConfirmPopup group="forgotPassword"></ConfirmPopup>
                    <button @click.prevent="forgotPassword" class="btn btn-primary" ref="forgotButton">Reset my password</button>

                    <hr />
                    <a href="javascript:void()" @click.prevent="showForgotPassword = false">Cancel resetting
                        password</a>
                    go back to <a href="javascript:void()" @click.prevent="showForgotPassword = false">login screen</a>.

                </div>

            </form>
        </div>


    </div>


</template>
    
<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useToast } from "vue-toastification";
import ConfirmPopup from 'primevue/confirmdialog';

export default {
    data() {
        return {
            form: {
                email: "",
                password: "",
                errors: []
            },
            forgotEmail: "",
            showForgotPassword: false,
            error: [],
            linkProvider: false
        };
    },

    components: {
            ConfirmPopup
        },

    props: ['stayOnPage'],

    methods: {
        submit() {

            console.log("in submit");

            firebase
                .auth()
                .signInWithEmailAndPassword(this.form.email, this.form.password)
                .then((data) => {this.loginSuccess(data)})
                .catch((e) => {this.loginError(e)});
        },




        forgotPassword(ev) {
            console.log("TARGET", ev.currentTarget);
            console.log("REFS", this.$refs.forgotButton);
            firebase.auth().fetchSignInMethodsForEmail(this.forgotEmail).then((result) => {
                console.log("METHODS", result);

                if (result.includes("password")) {
                    firebase.auth().sendPasswordResetEmail(this.forgotEmail, { url: window.location.href }).then((success) => {
                        console.log("Forgot password success", success);
                        this.forgotEmail = "";
                        useToast().success("Please check your email for directions on how to continue resetting your password.");
                    }).catch((error) => {
                        console.log("Forgot password error", error);
                    })
                } else {
                    this.$confirm.require({
                        target: this.$refs.forgotButton,
                        message: 'Looks like you usually login using your ' + result.join(" or ") + " account.  You can use one of those methods to login, or continue assigning a password to your account.",
                        group: "forgotPassword",
                        header: 'Assign Password?',
                        acceptLabel: "Continue",
                        rejectLabel: "I'll login another way",
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            firebase.auth().sendPasswordResetEmail(this.forgotEmail, { url: window.location.href }).then((success) => {
                                console.log("Forgot password success", success);
                                this.forgotEmail = "";
                                useToast().success("Please check your email for directions on how to continue setting a password.");
                            }).catch((error) => {
                                console.log("Forgot password error", error);
                            })
                        },
                        onShow: () => {

                        }

                    });
                }

            }).catch((error) => {
                console.log("METHODS ERROR", error);
            });

        },

        closeLoginOffcanvas() {
            document.getElementById('closeLoginOffCanvas').click();
        },
        loginWithFacebook() {
            console.log("HERE");
            var provider = new firebase.auth.FacebookAuthProvider();
            firebase.auth().useDeviceLanguage();
            provider.setCustomParameters({
                'display': 'popup'
            });
            firebase
                .auth().signInWithPopup(provider).then((d)=>{this.loginSuccess(d)})
                .catch((e)=>{this.loginError(e)});
        },
        loginWithGoogle() {
            console.log("HERE");
            var provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().useDeviceLanguage();
            provider.setCustomParameters({
                'display': 'popup'
            });
            firebase
                .auth().signInWithPopup(provider).then((d)=>{this.loginSuccess(d)})
                .catch((e)=>{this.loginError(e)});
        },
        loginSuccess(data) {
            console.log("LOGIN DATA", data);

            var user = data.user;

            if (this.linkProvider) {

                user.linkWithCredential(this.linkProvider).then((a) => { 
                    console.log("Did some linking and completed successfully", a);
                    useToast().success("We successfully linked your accounts together.");
                }).catch((error) => { console.log(error, "in first error"); });
                if (!this.stayOnPage)
                    this.$router.replace({ name: "Dashboard" });
            } else {
                console.log("no Linking");
                //if (!this.stayOnPage)
                //    this.$router.replace({ name: "Dashboard" });

                useToast().success("Login Complete.");
                //this.$gtag.event('login', { method: data.additionalUserInfo.providerId })
            }
            try {
                document.getElementById('closeLoginOffCanvas').click();
            } catch (e) { console.log("a"); }

        },
        loginError(error) {
            this.error = [];
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            console.log(credential, email, errorMessage, errorCode, this);

            if (error.code == "auth/account-exists-with-different-credential") {
                this.askToLink(email, credential);
            } else {
                this.error.push("Sorry, an error happened.  Please try again. (" + error.message + ")");
            }

        },
        askToLink(email, credential) {

            console.log(email, credential)
            var self = this;



            firebase.auth().fetchProvidersForEmail(email)
                .then(providers => {
                    //providers returns this array -> ["google.com"]
                    // You need to sign in the user to that google account
                    // with the same email.
                    // In a browser you can call:
                    // var provider = new firebase.auth.GoogleAuthProvider();
                    // provider.setCustomParameters({login_hint: error.email});
                    // firebase.auth().signInWithPopup(provider)
                    // If you have your own mechanism to get that token, you get it
                    // for that Google email user and sign in
                    /*
                    firebase.auth().signInWithCredential(googleCred)
                      .then(user => {
                        // You can now link the pending credential from the first
                        // error.
                        user.linkWithCredential(error.credential)
                      })
                      .catch(error => log(error))
                    */
                    providers.map((item) => {
                        item.replace(".com", "");
                        if (item == "password") {
                            return "RefinedHealth username / password";
                        } else {
                            return item.charAt(0).toUpperCase() + item.slice(1) + " account";
                        }
                    });

                    if (providers.length == 1) {
                        self.error.push("Looks like you previously registered using your " + providers.pop() + ".  Please login using that method first to link these accounts.");
                    } else {
                        self.error.push("Looks like you previously registered using one of the following methods: " + providers.join(", ") + ".  Please login via one of these methods to link your accounts.");
                    }

                    self.linkProvider = credential;
                    console.log(self.linkProvider);

                });
        }
    },

};
</script>
<style>
.p-confirm-dialog-message { max-width: 300px;}</style>