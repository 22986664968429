
import Vuex from "vuex";


export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      isVerified: false,
      data: null,
      photo: null,
      profile: {
        basicInfo: {
          licensedLocations: [],
          activeCertifications: []
        }
      },
      displayName: null,
    },
    agency: false
  },
  getters: {
    user(state){
      return state.user
    },
    agency(state) {
      return state.agency
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_IS_VERIFIED(state, value) {
      state.user.isVerified = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_USER_PHOTO(state, value) {
      state.user.photo = value;
    },
    SET_PROFILE(state, profile) {
      state.user.profile = profile;
    },
    SET_ROLE(state, role) {
      if (!role || !role.type || (role.type != "recruiter" && role.type != "agency_admin" && role.type != "super_admin")) {
        role = {type: "nurse"};
      }
      state.user.role = role;
    },
    SET_AGENCY(state, agency) {
      state.agency = agency;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);

      if (user) {
        commit("SET_IS_VERIFIED", user.emailVerified);
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
          photoUrl: user.photoURL,
          _user: user,
        });
        commit("SET_USER_PHOTO", user.photoURL);
      } else {
        commit("SET_USER", null);
        commit("SET_ROLE", null);
        commit("SET_PROFILE", null);
        commit("SET_AGENCY", null);
        commit("SET_USER_PHOTO", null);
        
      }
    },
    setPhoto({commit}, photo) {
      //console.log("ABOUT TO COMMIT", photo);
      if (photo && photo != "") {
        //console.log("COMMIT PHOTO", photo);
        commit("SET_USER_PHOTO", photo);
      }
    },
    setProfile({commit}, data) {
      if (data) {
        commit("SET_PROFILE", data);
      }
    },
    setEmailVerified({commit}, data) {
      if (data) {
        commit("SET_IS_VERIFIED", data === true);
      }
    },
    setRole({commit}, data) {
      if (data) {
        commit("SET_ROLE", data);
      }
    },
    setAgency({commit}, data) {
      if (data) {
        commit("SET_AGENCY", data);
      }
    }
  }
});