import { createApp } from 'vue'
import App from './App.vue'
import firebase from "firebase/compat/app";
import router from './routes'
import store from './store.js'

import "bootstrap";
import "./css/custom.scss";

import PrimeVue from 'primevue/config';
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import 'primeicons/primeicons.css';


import toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import InstantSearch from 'vue-instantsearch/vue3/es';

import 'bootstrap-icons/font/bootstrap-icons.css'

import {authStateChanged} from './authHandler.js';

import VueMobileDetection from 'vue-mobile-detection';

import LayoutManager from './layouts/Layout.vue'
import DefaultLayout from './layouts/DefaultLayout';
import BlankLayout from './layouts/BlankLayout';

import VueGtag from "vue-gtag";

import ConfirmationService from 'primevue/confirmationservice';

import LoginBox from './components/molecules/LoginBox';




const toastOptions = {
  // You can set your default options here
};

const firebaseConfig = {
  apiKey: "AIzaSyAHZmZKg3dt8d0Q69Aviqqj9LXcVCDUXDo",
  authDomain: "nursingjobs-8d763.firebaseapp.com",
  projectId: "nursingjobs-8d763",
  storageBucket: "nursingjobs-8d763.appspot.com",
  messagingSenderId: "294094660978",
  appId: "1:294094660978:web:f3de06e501f4fe1acb69eb",
  measurementId: "G-RRD8ZVEF3L"
};


firebase.initializeApp(firebaseConfig);
if (location.hostname === "localhost") {
  //firebase.firestore().useEmulator("localhost", 8084);
  //firebase.functions().useEmulator("localhost", 5001);
  //firebase.auth().useEmulator("http://localhost:9099");
}

createApp(App)
  .component("BlankLayout", BlankLayout)
  .component("DefaultLayout", DefaultLayout)
  .component('Layout', LayoutManager)
  .component('LoginBox', LoginBox)
  .use(router)
  .use(store)
  .use(InstantSearch)
  .use(PrimeVue)
  .use(toast, toastOptions)
  .use(VueMobileDetection)
  .use(ConfirmationService)
  .use(VueGtag, {
    config: { id: "G-RRD8ZVEF3L" }
  })
  .mount('#app');
 

firebase.auth().onAuthStateChanged(user => {
  console.log("AUTH STATE CHANGED", user);
  authStateChanged(user);


});


