<template>
    <div>
    <p><router-link to="/dashboard" class="">&lt; Back to my Profile</router-link></p>
    <h4>Invites</h4>


    <div v-if="isLoading">
        Loading
    </div>
    <div v-if="!isLoading">

        <div v-if="invites.length == 0">No invites found</div>

        <div v-else class="card mb-3" v-for="invite in invites" :key="invite.agencyId">
            <div class="card-body ">
                <div class="row">
                    <div class="col align-items-center d-flex" style="flex: 0; width: 200px">
                        <div style="width: 200px; text-align: center">
                            <img width="180" :src="
                              'https://firebasestorage.googleapis.com/v0/b/nursingjobs-8d763.appspot.com/o/agencies%2F' +
                              invite.agencyId +
                              '%2Flogo.png?alt=media'
                            " alt="Logo" />
                        </div>
                    </div>
                    <div class="col ps-3 align-items-center d-flex">
                        <h5>{{agencies[invite.agencyId].name}}</h5>
                    </div>
                    <div class="col ps-3 align-items-center d-flex">
                        You are invited to join as a Recruiter.
                    </div>
                    <div class="col  align-items-center d-flex">
                        <button @click.prevent="acceptInvite(invite)" class="btn btn-outline-success">Accept Invite
                        </button> &nbsp;
                        <button class="btn btn-outline-danger" @click.prevent="deleteItem(invite)">Decline</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
</template>
    
    
<script>

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { mapGetters } from "vuex";

import { useToast } from "vue-toastification";

import updateRoleFromToken from '../../authHandler.js';

export default {

    components: {

    },

    created() {


        this.getInvites();

    },

    data() {
        return {
            isLoading: true,
            invites: [],
            documents: [],
            errors: [],
            deleteDocumentId: "",
            showDelete: false,
            agencies: {},
            agencyIds: []

        };
    },

    watch: {
        user: {
            deep: true,
            handler: function () {
                this.getInvites();
                //if (this.user && this.user.loggedIn) { }
            }

        }
    },
    methods: {

        updateClaims() {
            console.log("About to update claims", this.user.role);
            firebase.auth().currentUser.getIdTokenResult(true).then((result) => {
                    console.log("In Update Callback", result);
                    updateRoleFromToken(result);
                });
        },
        getInvites() {

            if (this.user && this.user.loggedIn) {
                firebase.firestore().collectionGroup("invites").where("invitee", "==", this.user.data.email).get().then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        throw new Error("No Invites Found");
                    }
                    var agencyIds = [];
                    this.invites = querySnapshot.docs.map((doc) => {
                        var data = doc.data();
                        data.docId = doc.id;
                        agencyIds.push(data.agencyId);
                        return data;
                    });
                    console.log(this.invites, "INVITES", agencyIds);
                    return agencyIds;
                }).then((agencyIds) => {
                    // load agency data...
                    return firebase.firestore().collection("agencies").where(firebase.firestore.FieldPath.documentId(), 'in', agencyIds).get();
                }).then((querySnapshot) => {
                    var agencies = {};
                    for (var i in querySnapshot.docs) {
                        var data = querySnapshot.docs[i].data();
                        agencies[querySnapshot.docs[i].id] = data;
                    }
                    console.log("AGENCIES", agencies);
                    this.agencies = agencies;
                }).catch(() => {
                    this.agencyIds = [];
                        this.agencies={};
                        this.invites = [];
                        this.isLoading = false;
                }).finally(() => {
                    this.isLoading = false;
                })
            }
        },
        deleteItem(item) {
            console.log("DELETE", item);
            firebase.firestore().collection("agencies").doc(item.agencyId).collection("invites").doc(item.invitee).delete().then(() => {
                useToast().success("The invite has been declined");
                this.getInvites();
            })
        },
        acceptInvite(item) {
            console.log("accept", item);
            const getUrl = firebase.app().functions().httpsCallable("invites-acceptInvite")

            getUrl(item).then((a) => {

                console.log("accept", a);
            }).then(()=>{
                console.log("in return");
                firebase.auth().currentUser.getIdTokenResult(true).then((result) => {
                    updateRoleFromToken(result);
                });
                useToast().success("The invite has been accepted.");
            });

        },

    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        })
    }
};
</script>