import firebase from "firebase/compat/app";
import store from './store.js'


var profileUnsubscribe;
var agencyUnsubscribe;


function cleanProfile(profile) {
  if (!profile.basicInfo)
    profile.basicInfo = {};

  if (!profile.basicInfo.activeCertifications) {
    profile.basicInfo.activeCertifications = [];
  }

  if (!profile.basicInfo.licensedLocations) {
    profile.basicInfo.licensedLocations = [];
  }

  return profile;
}

function updateRoleFromToken(result) {
  //console.log("CLAIMS", result.claims.role);
  var role = result.claims.role ? result.claims.role : { type: "nurse" };
  store.dispatch("setRole", role);

  if (role.type == "agency_admin" || role.type == "recruiter") {
    // load agency document.
    agencyUnsubscribe = firebase.firestore().collection("agencies").doc(role.agencyId).onSnapshot((doc) => {
      var agency = doc.data();
      agency.agencyId = role.agencyId;
      store.dispatch("setAgency", agency);
    })
  }
}

function authStateChanged(user) {
  if (user) {
    //console.log("IN USER UPDATE");
    user.getIdTokenResult().then((result) => {

      updateRoleFromToken(result);


    });
    profileUnsubscribe = firebase.firestore().collection("profiles").doc(user.uid).
      onSnapshot(function (doc) {
        //console.log("DISPATCH", doc.data());

        var profile = cleanProfile(doc.data());

        store.dispatch("setPhoto", doc.data().profile_image);
        store.dispatch("setProfile", profile);

      });

    //console.log("ONAUTHSTATE", user, user.getIdTokenResult);
    store.dispatch("fetchUser", user);


  } else {
    if (profileUnsubscribe)
      profileUnsubscribe();
    if (agencyUnsubscribe)
      agencyUnsubscribe();
    store.dispatch("fetchUser", null);
  }
}

export { updateRoleFromToken, authStateChanged }