<template>
    <div class="card border-0">
        <div class="card-body">
            
            <div class="progress mb-2">
                <div :class="'progress-bar ' + progressColor" role="progressbar" :style="'height: 20px; width: ' + currentProfileStatus + '%;'"
                    :aria-valuenow="currentProfileStatus" aria-valuemin="0" aria-valuemax="100">Profile Strength: {{ currentProfileStatus
                    }}% <template v-if="currentProfileStatus == 100">Refined</template>
                </div>
            </div>
            <div class="" v-if="!canApply">
                <div class="small text-danger"><b>You must fill in the Basic Information, Work History, Education
                        sections, and have a verified e-mail address to be able to apply for jobs.</b></div>
            </div>
            <div class="" v-else>
                <div class="small text-success"><b>Congratulations! You can now start applying for jobs.</b></div>
            </div>

        </div>
    </div>
</template>


<script>

import { mapGetters } from "vuex";


export default {




    props: ['profileData'],


    computed: {
        ...mapGetters({
            userLoggedIn: "user"
        }),

        user() {
            if (this.profileData) {
                return {profile: this.profileData}
            } else {
                return this.userLoggedIn;
            }
        },

        canApply() {
            return this.user.profile && this.user.profile.status && this.user.profile.status.canApply && this.user.isVerified;
        },

        currentProfileStatus() {
            if (this.user && this.user.profile && this.user.profile.status) {
                var total = this.user.profile.status.overall || 0;
                if (total == 100 && !this.canApply) {
                    total = 99;
                }
                return total;
            } else {
                return 0;
            }
        },
        progressColor() {
            if (this.user && this.user.profile && this.user.profile.status && this.user.profile.status.canApply && this.user.isVerified) {
                return "bg-primary"; 
            } else {
                return "bg-danger";
            }
        }
    }
}
</script>
