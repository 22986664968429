<script>
import { getCurrentInstance } from 'vue';



export default {
  name: 'LayoutManager',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  created() {
    // Check if the layout component
    // has already been registered.

    //const isComponent = name => typeof resolveDynamicComponent(name) !== 'string'
    
    console.log("INSTANCE", this.name, getCurrentInstance());
    this.$emit('update:layout', this.name);
  },


  render() {
    return this.$slots.default();
  },
};

</script>